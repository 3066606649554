<div *ngIf="isSaving" class="spinner-outer-wrapper save-data">
  <mat-spinner diameter="100"></mat-spinner>
</div>
<app-product-search></app-product-search>
<mat-sidenav-container class="cart-container">
  <mat-sidenav #sidenav mode="over" class="cart-sidenav" [fixedInViewport]="true" position="end">
    <div class="basket-header">
      <div (click)="sidenav.toggle()" class="close-cart-sidenav">
        <p i18n="@@feedback.product.yourBasket">Your basket</p>
        <span class="material-symbols-outlined close-basket-sidenav">close</span>
      </div>
      <a class="edit-cart-btn">
        <span class="items-in-basket" style="float: left;font-size: 12px;">
          <i class="fa fa-shopping-basket" style="font-size: 16px;"></i>
          {{ this.itemInBasket }} <span i18n="@@feedback.product.cart.items">item(s)</span>
        </span>
        <small [hidden]="true" (click)="editCart()" i18n="@@feedback.product.button.editBasket">Edit basket</small>
      </a>
    </div>
    <div class="basket-container">
      <mat-tab-group class="basket-tab-group">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon fontSet="fa" fontIcon="fa-shopping-basket"></mat-icon>
            <span i18n="@@feedback.product.cart.tab.add-on.tab.basket">Basket</span>
          </ng-template>
          <mat-list role="list">
            <p *ngIf="this.obj.basket.length === 0" i18n="@@feedback.product.emptyBasket">Your basket is empty</p>
            <mat-list-item role="listitem" *ngFor="let prod of this.obj.basket; last as last; let index=index;">
              <div class="image-wrapper">
                <img *ngIf="prod.Image_URL" src="{{ this.strReplace(prod.Image_URL) }}" width="75px"  alt="{{prod.Name}}"/>
              </div>
              <div class="product-info-wrapper">
                <h4 mat-line>{{prod.Name | titlecase}}</h4>
                <small mat-line><span i18n="@@feedback.product.cart.size">Size:</span>&nbsp;{{prod.ColourSize}}</small>
                <small mat-line><span i18n="@@feedback.product.cart.qty">Qty:</span>&nbsp;{{prod.qty}}</small>
                <div style="justify-content: space-between; display: flex">
                  <small mat-line><ngb-rating [(rate)]="prod.starRating" [max]="prod.starRating" [readonly]="true"></ngb-rating></small>
                  <small mat-line>{{ prod.Unit_Price | currency: prod.Currency_Code: 'symbol': '1.2-2' }}</small>
                </div>
              </div>
              <div class="update-cart-wrapper" *ngIf="!enableEditCart">
                <div class="qty-wrapper">
                  <button class="btn btn-qty" (click)="minus(index)"><i class="fa fa-minus"></i></button>
                  <input type="text" class="form-control" [(ngModel)]="prod.qty" [readOnly]="true">
                  <button class="btn btn-qty" (click)="plus(index)"><i class="fa fa-plus"></i></button>
                </div>
                <div class="remove-wrapper">
                  <span (click)="removeFromBasket(prod, this.obj.basket)" i18n="@@feedback.product.button.remove">Remove</span>&nbsp;
                  <span class="material-symbols-outlined">delete</span>
                </div>
              </div>
              <mat-divider *ngIf="!last"></mat-divider>
            </mat-list-item>
          </mat-list>
        </mat-tab>
        <mat-tab [disabled]="true">
          <ng-template mat-tab-label>
            <mat-icon class="icon-all-products">filter_alt</mat-icon>
            <span i18n="@@feedback.product.cart.tab.all-products.label">All Products</span>
          </ng-template>
          <div class="all-products-search-product">
            <input id="product-search-box-all-products" (focus)="this.isFocused = true" (blur)="this.isFocused = false" [disabled]="formCompleted" name="product" i18n-placeholder="@@feedback.product.id.placeholder" placeholder="Enter Product Name" autocomplete="off" #searchProductBox [(ngModel)]="prod.id" class="search-box" (input)="searchProducts(prod.id)"/>
            <div *ngIf="isLocationLoading" class="spinner-outer-wrapper">
              <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
              <div class="spinner-logo-wrapper">
                <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
              </div>
            </div>
            <mat-icon [hidden]="isLocationLoading" *ngIf="!prod.id" aria-hidden="false" (click)="searchProductBox.focus()" class="add-on-search-icon" aria-label="search icon">search</mat-icon>
            <mat-icon [hidden]="isLocationLoading" *ngIf="prod.id" aria-hidden="false" class="add-on-close-icon" aria-label="close icon" (click)="clearSearch(searchProductBox)" >close</mat-icon>
            <ul class="search-result" *ngIf="!hideList">
              <li *ngFor="let prod of productList$ | async" [ngClass]="prod.Unit_Price === '0'? 'disabled': ''">
                <mat-icon aria-hidden="false" class="add-on-add_shopping_cart-icon" aria-label="add_shopping_cart icon">add_shopping_cart</mat-icon>
                <a (click)="addToBasket(prod)">
                  {{ prod.Name }} - <span class="product-search-result-size">{{ prod.ColourSize }}</span>
                </a>
              </li>
            </ul>
          </div>
          <section class="all-products-basket-list">
            <div>
              <h5><span i18n="@@feedback.product.cart.tab.filterBy.label">Filter by</span>:&nbsp;<span class="selected-filter" *ngIf="this.selectedFilter"><mat-icon class="icon-add-on">filter_alt_off</mat-icon>{{this.selectedFilter}}</span></h5>
              <div class="filter-by-list-wrapper">
                <mat-radio-group id="filter-by-list" aria-label="Select an option" class="example-radio-button" [(ngModel)]="this.selectedFilter">
                  <mat-radio-button [disabled]="isLocationLoading" (change)="allProductFilterChange($event)" class="example-radio-button" *ngFor="let filter of productFilterList" [value]="filter.code">
                    {{ filter.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <span *ngIf="!addOnLoading">
                <div class="all-product-list-item" *ngFor="let product of this.allProductList; let index=index;">
                  <div class="update-cart-wrapper" [ngClass]="product.Unit_Price === '0'? 'disabled': ''">
                    <mat-checkbox [checked]="product.in_basket" (change)="onAddOnchange($event, product, this.allProductList)" [disabled]="product.Unit_Price === '0'"><span class="add-on-product-name">{{ product.Name | uppercase}}</span></mat-checkbox>
                    <span class="add-on-product-list-size"><b i18n="@@feedback.product.cart.tab.add-on.size.label">Size:</b>&nbsp;&nbsp;{{ product.ColourSize }}</span>
                    <span class="qty-wrapper" *ngIf="!enableEditCart">
                      <button class="btn btn-qty" (click)="AllProductMinus(index)" [disabled]="!product.Unit_Price"><i class="fa fa-minus"></i></button>
                      <input type="text" class="form-control" [(ngModel)]="product.qty" [readOnly]="true" [disabled]="product.Unit_Price === '0'">
                      <button class="btn btn-qty" (click)="AllProductPlus(index)" [disabled]="product.Unit_Price === '0'"><i class="fa fa-plus"></i></button>
                    </span>
                    <span class="all-product-price">{{ product.Unit_Price | currency: product.Currency_Code:'symbol':'1.2-2'}}</span>
                  </div>
                </div>
              </span>
            </div>
          </section>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon fontSet="fa" fontIcon="fa-plus-circle" class="icon-add-on"></mat-icon>
            <span i18n="@@feedback.product.cart.tab.add-on.label">Add-on</span>
          </ng-template>
          <div class="add-on-search-product">
            <input id="product-search-box-add-on" (focus)="this.isFocused = true" (blur)="this.isFocused = false" [disabled]="formCompleted" name="product" i18n-placeholder="@@feedback.product.id.placeholder" placeholder="Enter Product Name" autocomplete="off" #searchProductBox [(ngModel)]="prod.id" class="search-box" (input)="searchProducts(prod.id)"/>
            <div *ngIf="isLocationLoading" class="spinner-outer-wrapper">
              <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
              <div class="spinner-logo-wrapper">
                <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
              </div>
            </div>
            <mat-icon [hidden]="isLocationLoading" *ngIf="!prod.id" aria-hidden="false" (click)="searchProductBox.focus()" class="add-on-search-icon" aria-label="search icon">search</mat-icon>
            <mat-icon [hidden]="isLocationLoading" *ngIf="prod.id" aria-hidden="false" class="add-on-close-icon" aria-label="close icon" (click)="clearSearch(searchProductBox)" >close</mat-icon>
            <ul class="search-result" *ngIf="!hideList">
              <li *ngFor="let prod of productList$ | async" [ngClass]="prod.Unit_Price === '0'? 'disabled': ''">
                <mat-icon aria-hidden="false" class="add-on-add_shopping_cart-icon" aria-label="add_shopping_cart icon">add_shopping_cart</mat-icon>
                <a (click)="addToBasket(prod)">
                  {{ prod.Name }} - <span class="product-search-result-size">{{ prod.ColourSize }}</span>
                </a>
              </li>
            </ul>
          </div>
          <section class="add-ons-basket-list">
            <div *ngIf="addOnLoading">
              <mat-spinner diameter="50" style="margin:0 auto;" mode="indeterminate"></mat-spinner>
            </div>
            <div *ngIf="!addOnLoading">
              <h5 i18n="@@feedback.product.cart.tab.add-on.list.label">Select your add-ons:</h5>
              <span>
                <div class="add-on-product-list-item" *ngFor="let addon of this.addOnProduct; let index=index;">
                  <div class="update-cart-wrapper" [ngClass]="addon.Unit_Price === '0'? 'disabled': ''">
                    <mat-checkbox [checked]="addon.in_basket" (change)="onAddOnchange($event, addon, this.addOnProduct)" [disabled]="addon.Unit_Price === '0'"><span class="add-on-product-name">{{ addon.Name | uppercase}}</span></mat-checkbox>
                    <span class="add-on-product-list-size"><b i18n="@@feedback.product.cart.tab.add-on.size.label">Size:</b>&nbsp;&nbsp;{{ addon.ColourSize }}</span>
                    <span class="qty-wrapper" *ngIf="!enableEditCart">
                      <button class="btn btn-qty" (click)="AddOnMinus(index)" [disabled]="!addon.Unit_Price"><i class="fa fa-minus"></i></button>
                      <input type="text" class="form-control" [(ngModel)]="addon.qty" [readOnly]="true" [disabled]="addon.Unit_Price === '0'">
                      <button class="btn btn-qty" (click)="AddOnPlus(index)" [disabled]="addon.Unit_Price === '0'"><i class="fa fa-plus"></i></button>
                    </span>
                    <span class="add-on-price">{{ addon.Unit_Price | currency: addon.Currency_Code:'symbol':'1.2-2'}}</span>
                  </div>
                </div>
              </span>
            </div>
          </section>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="basket-footer" *ngIf="this.obj.basket.length > 0" [hidden]="this.isFocused">
      <p class="cart-total"><b i18n="@@feedback.product.total">Total</b>: <span class="cart-total-price"><b>{{ this.subtotal | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</b></span></p>
      <div class="inner-footer" *ngIf="this.subtotal > 120.00 && this.locale === 'en-GB'">
        <svg class="k-logo" xmlns="http://www.w3.org/2000/svg" width="100" height="40.4494" viewBox="0 0 100 40.4494"><defs><style>.cls-1{fill:#ffb3c7;}</style></defs><g id="Lager_2" data-name="Lager 2"><g id="Layer_1" data-name="Layer 1"><rect class="cls-1" width="100" height="40.4494" rx="11.7384"></rect><path d="M80.0772,17.7235a6.194,6.194,0,1,0,0,10.2373v.75h3.5145V16.9727H80.0772Zm-3.1964,8.1388a3.0243,3.0243,0,1,1,3.1849-3.02A3.1059,3.1059,0,0,1,76.8808,25.8623Z"></path><rect x="28.2027" y="11.7416" width="3.678" height="16.9694"></rect><path d="M64.1542,16.6569a4.2694,4.2694,0,0,0-3.62,1.6383V16.9733h-3.5V28.711h3.5421V22.5424a2.4754,2.4754,0,0,1,2.6383-2.6591c1.5447,0,2.4325.9228,2.4325,2.6348V28.711h3.5107V21.2464C69.1577,18.5146,66.9857,16.6569,64.1542,16.6569Z"></path><path d="M43,17.7235a6.194,6.194,0,1,0,0,10.2373v.75h3.5145V16.9727H43Zm-3.1965,8.1388a3.0243,3.0243,0,1,1,3.1845-3.02A3.1058,3.1058,0,0,1,39.8034,25.8623Z"></path><path d="M51.9513,18.5017V16.9728H48.353V28.7111h3.606v-5.48c0-1.8491,2.0042-2.843,3.3949-2.843.014,0,.0276.0014.0417.0015V16.9735A4.3821,4.3821,0,0,0,51.9513,18.5017Z"></path><path d="M87.323,24.5476a2.2059,2.2059,0,1,0,2.206,2.2059A2.2057,2.2057,0,0,0,87.323,24.5476Z"></path><path d="M25.6675,11.7384H21.8558a9.7488,9.7488,0,0,1-3.9412,7.8678l-1.51,1.131,5.8513,7.9792h4.8106l-5.3837-7.342A13.5049,13.5049,0,0,0,25.6675,11.7384Z"></path><rect x="12.1204" y="11.7384" width="3.8185" height="16.9773"></rect></g></g></svg>
        <p i18n="@@feedback.product.footer.klarna.description" class="klarna-description">Klarna - Monthly payment options - Rate <strong>21.9%</strong></p>
        <ul>
          <li>
            <small><b i18n="@@feedback.product.klarna.12months">12 months</b> - {{ (this.subtotal + (this.subtotal * (21.9/100)))/12 | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</small>
          </li>
          <li *ngIf="this.subtotal >= 120.00">
            <small><b i18n="@@feedback.product.klarna.24months">24 months</b> - {{ (this.subtotal + (this.subtotal * (21.9/100)))/24 | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</small>
          </li>
          <li *ngIf="this.subtotal >= 200.00">
            <small><b i18n="@@feedback.product.klarna.36months">36 months</b> - {{ (this.subtotal + (this.subtotal * (21.9/100)))/36 | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</small>
          </li>
          <li *ngIf="this.subtotal >= 220.00">
            <small><b i18n="@@feedback.product.klarna.48months">48 months</b> - {{ (this.subtotal + (this.subtotal * (21.9/100)))/48 | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</small>
          </li>
        </ul>
        <small><b i18n="@@feedback.product.klarna.total">Total to Pay:</b> {{ (this.subtotal + (this.subtotal * (21.9/100))) | currency: this.obj.basket[0].Currency_Code:'symbol':'1.2-2' }}</small>
      </div>
      <button class="create-order-btn" type="button" (click)="createPurchaseOrder()" i18n="@@feedback.product.button.createPurchaseOrder" disabled>Send To Till</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="product-wrapper">
      <h2>
        <span *ngIf="this.choosenProducts.length">
          <span class="product-list-label" i18n="@@feedback.product.header.list">Product List</span>
          <span class="star-rating-filter-wrapper">
            <small class="star-rating-filter-label" i18n="@@feedback.product.filter.label">Filter by star rating:</small>
            <ngb-rating [(rate)]="filterStarRating" [max]="5" [readonly]="false" resettable="true"></ngb-rating>
          </span>
        </span>
        <span *ngIf="this.choosenProducts.length === 0">
          <button type="button" class="no-feedback-provided-btn" (click)="openModalNoRating(noRatingDialog)" [disabled]="isLocationLoading"><span i18n="@@feedback.product.noRating">No Fit</span><span class="material-symbols-outlined no-star">star</span></button>
          <div *ngIf="isLocationLoading" class="spinner-outer-wrapper">
            <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
            <div class="spinner-logo-wrapper">
              <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
            </div>
          </div>
        </span>
        <span class="in-basket-wrapper" (click)="!formCompleted ? sidenav.toggle(): ''">
          <span>
            <span class="material-symbols-outlined in-basket">shopping_bag</span>
            <i class="items-in-cart-counter">{{ this.itemInBasket }}</i>
          </span>
        </span>
      </h2>
      <ul class="product-result" *ngIf="choosenProducts.length">
        <li class="product-list" *ngFor="let product of choosenProducts | searchFilter: filterStarRating ; let i = index;" [ngClass]="{'error' : product.error}" >
          <div [className]="product.submitted ? 'submitted-product' : ''" class="form-row">
            <h4 class="form-row-contents">
              <div class="row-content-top">
                <span class="inner-span product-name">{{ product.Name.trim() | uppercase }}</span>&nbsp;
                <span class="colour-size">{{ product.ColourSize | uppercase }}</span>
                <span class="product-list-price">{{ product.Unit_Price | currency: product.Currency_Code: 'symbol': '1.2-2' }}</span>
              </div>
              <div class="row-content-bottom">
                <div id="star-rating">
                  <ngb-rating (rateChange)="onStarRateChange($event, product)" [(rate)]="product.starRating" [max]="5" [readonly]="false" resettable="true" ></ngb-rating>
                </div>
                <div class="edit-container">
                  <button *ngIf="!product.in_basket" class="remove-product feedback-product" (click)="removeProduct(i)" [disabled]="formCompleted"><span class="material-symbols-outlined">delete</span></button>
                  <button *ngIf="product.in_basket" (click)="sidenav.toggle()" class="in-basket-icon feedback-product" [disabled]="formCompleted"><span class="material-symbols-outlined">shopping_bag</span></button>
                  <button *ngIf="!formCompleted" (click)="toggle(product, choosenProducts)" class="feedback-product edit" [disabled]="formCompleted"><span class="material-symbols-outlined">edit_note</span></button>
                  <button *ngIf="!formCompleted && !product.in_basket" (click)="addToBasket(product)" class="feedback-product" [disabled]="formCompleted"><span class="material-symbols-outlined add">shopping_bag</span><i class="material-symbols-outlined">add</i></button>
                  <button *ngIf="!formCompleted && product.in_basket" (click)="removeFromBasket(product, choosenProducts)" class="feedback-product basket remove" [disabled]="formCompleted"><span class="material-symbols-outlined remove">shopping_bag</span><i class="material-symbols-outlined">remove</i></button>
                  <div class="submitted-icon" *ngIf="product.submitted"><i class="fa fa-check"></i></div>
                </div>
              </div>
            </h4>
            <form name="form" (ngSubmit)="feedback.form.valid && onFeedbackSubmit(feedback, product, $event)" #feedback="ngForm" *ngIf="product.show">
              <input type="hidden" name="product.id" [(ngModel)]="product.id" ngModel>
              <input type="hidden" name="product.price" [(ngModel)]="product.price" ngModel>
              <input type="hidden" name="product.name" [(ngModel)]="product.name" ngModel>
              <input type="hidden" name="product.description" [(ngModel)]="product.description" ngModel>
              <div id="inner-product-form">
                <div class="product-img-des-wrapper">
                  <img class="product-img" src="{{ this.strReplace(product.Image_URL) }}"  alt=""/>
                  <span class="product-description">{{ product.Description }}</span>
                </div>
                <div class="submit-btn-wrapper">
                  <button *ngIf="!product.in_basket" (click)="addToBasket(product)" [disabled]="formCompleted" type="button" class="add-to-basket" i18n="@@feedback.product.button.addToCart">Add To Basket</button>
                  <button *ngIf="product.in_basket" (click)="removeFromBasket(product, choosenProducts)" [disabled]="formCompleted" type="button" class="remove-from-basket"  i18n="@@feedback.product.button.removeFromCart">Remove From Basket</button>
                </div>
                <!--                <div *ngIf="product.error" class="error-danger error-feedback">-->
                <!--                  <p>{{ error.error }}</p>-->
                <!--                  <p>{{ error.message }}</p>-->
                <!--                </div>-->
              </div>
            </form>
          </div>
        </li>
      </ul>
      <div *ngIf="choosenProducts.length > 0" class="confirm-complete-outer">
        <p style="background-color: transparent" *ngIf="this.message" [ngClass]="this.messageError?'alert-danger':'alert-success'" class="alert after-save-message ">{{ this.message }}</p>
        <button (click)="save('continue', confirmSaveExit)" [disabled]="formCompleted || isLocationLoading" type="button" class="save-continue" i18n="@@feedback.product.button.saveAndContinue">Save & Continue</button>
        <button (click)="save('leave', confirmSaveExit)" [disabled]="formCompleted || isLocationLoading" type="button" class="save-leave" i18n="@@feedback.product.button.saveAndExit">Save & Exit</button>
        <button (click)="openModal(confirmSubmission)" type="button" class="confirm-complete" [disabled]="formCompleted || isLocationLoading">
          <span>{{ confirmCompleteText }}</span>
        </button>
        <div *ngIf="isLocationLoading" class="spinner-outer-wrapper">
          <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
          <div class="spinner-logo-wrapper">
            <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
          </div>
        </div>
      </div>
    </div>
    <ng-template #confirmSaveExit>
      <button type="button" class="decline-complete-modal" matDialogClose><span i18n="@@feedback.product.button.cancel">Cancel</span></button>
      <button type="button" class="confirm-complete-modal" (click)="dialogRef.close('ok')"><span i18n="@@feedback.product.button.ok">Ok</span></button>
    </ng-template>
    <ng-template #confirmSubmission>
      <div id="purchased-options-container" class="options-container">
        <label id="purchased-radio-group-label" i18n="@@feedback.product.label.purchase">Purchased?</label>
        <mat-radio-group [(ngModel)]="this.obj.purchased"  (change)="onChange($event)">
          <mat-radio-button id="purchased-yes" value="yes" i18n="@@feedback.product.purchase.yes">Yes</mat-radio-button>
          <mat-radio-button id="purchased-no" value="no" i18n="@@feedback.product.purchase.no">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-radio-group *ngIf="!this.removeReasonCodes" id="reason-list" [(ngModel)]="this.obj.reason" required aria-label="Select an option" name="reasonCode">
        <mat-radio-button [disabled]="this.obj.purchased === 'yes'" (change)="radioCodeChange($event)" class="example-radio-button" *ngFor="let reason of reasonCodes" [value]="reason.Code">
          {{ reason.Description }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="comments-section" *ngIf="this.obj.reason === 'OTHER'">
        <div><label for="reason-comments" i18n="@@feedback.product.reason.comments">Comments:</label></div>
        <textarea rows="2" cols="50" id="reason-comments" class="comments" name="comments" [(ngModel)]="this.obj.comments"></textarea>
      </div>
      <div class="error-danger error-feedback" *ngIf="reasonCodeError">
        <p class="reason-code-error-msg" i18n="@@feedback.product.error.reason.message">Reason is a required field</p>
      </div>
      <p class="paragraph-modal" i18n="@@feedback.product.message.confirm.complete">Submit Feedback</p>
      <button type="button" [disabled]="this.obj.purchased === 'no' && !this.removeReasonCodes && this.obj.reason === '' || ((this.obj.reason=='OTHER' && this.obj.comments == ''))" class="confirm-complete-modal" (click)="confirmComplete(choosenProducts)"><span i18n="@@feedback.product.button.yes">Yes</span></button>
      <button type="button" matDialogClose class="decline-complete-modal"><span i18n="@@feedback.product.button.no">No</span></button>
    </ng-template>
    <ng-template #noRatingDialog>
      <div *ngIf="!this.removeReasonCodes">
        <h4 i18n="@@feedback.product.noReason.header">Please choose a reason</h4>
        <mat-radio-group id="reason-list-no-rating" aria-label="Select an option" class="example-radio-button" [(ngModel)]="this.obj.reason">
          <mat-radio-button (change)="radioCodeChange($event)" class="example-radio-button" *ngFor="let reason of reasonCodes" [value]="reason.Code">
            {{ reason.Description }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="comments-section" *ngIf="this.obj.reason === 'CFFANFOTHE'">
          <div><label for="reason-comments" i18n="@@feedback.product.reason.comments">Comments:</label></div>
          <textarea rows="2" cols="50" id="reason-comments-no-fit" class="comments" name="comments" [(ngModel)]="this.obj.comments"></textarea>
        </div>
      </div>
      <button (click)="completeWithNoRating(noRatingDialog)" type="button" class="confirm-complete" [disabled]="this.obj.purchased === 'no' && !this.removeReasonCodes && this.obj.reason === '' || ((this.obj.reason=='CFFANFOTHE' && this.obj.comments == ''))">
        <span>{{ confirmCompleteText }}</span>
      </button>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>
