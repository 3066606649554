import {Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {DOCUMENT, getLocaleCurrencyCode, APP_BASE_HREF} from '@angular/common';
import {Router} from '@angular/router';
import {ProductService} from '../product.service';
import {CustomerService} from '../customer.service';
import {FormBuilder} from '@angular/forms';
import {SharedService} from '../shared.service';
import {FitterService} from '../fitter.service';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of, Subject} from 'rxjs';
import {Product} from '../product';
import {debounceTime, distinctUntilChanged, shareReplay, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.less']
})
export class ProductComponent implements OnInit {
  choosenProducts: Array<any>;
  reasonCodes: Array<any>;
  addOnProduct: Array<any>;
  productList$: Observable<Product[]>;
  productFilterList: Array<any>;
  allProductList: Array<any>;
  selectedFilter: '';
  @ViewChild('searchProductBox') nameField: ElementRef;
  isLocationLoading = false;
  hideList = true;
  private searchTerms = new Subject<object>();
  prod = {
    id: null,
    barcodeValue: null,
    locale: null
  };
  storeCountry = localStorage.getItem('storeCountry');
  removeReasonCodes = false;
  filterReasonType = 'CCFA';
  noRatingReason: string;
  customerName: string;
  reasonCodeError = false;
  addOnLoading = true;
  isFocused = false;
  public isSaving = false;
  public enableEditCart = false;
  public inputnumber = 1;
  public locale: string;
  public error: any;
  public show = false;
  public submitted = false;
  public formCompleted = false;
  public confirmCompleteText = $localize`:@@feedback.button.confirm.complete:Confirm & Complete`;
  private i18nObj: any;
  public confirmationReady = false;
  public dialogRef: any;
  title = $localize`:@@feedback.product:Product`;
  public message: string;
  public messageError = false;
  public filterStarRating = NaN;
  obj = {
    purchased: 'no',
    reason: '',
    comments: '',
    fitter: undefined,
    customer: undefined,
    product: undefined,
    basket: []
  };
  code = this.localeId;
  public subtotal = 0;
  itemInBasket = 0;
  constructor(private fb: FormBuilder,
              private productService: ProductService,
              private sharedService: SharedService,
              private customerService: CustomerService,
              private fitterService: FitterService,
              public dialog: MatDialog,
              private router: Router,
              @Inject(LOCALE_ID) public localeId: string,
              @Inject(DOCUMENT) private document: Document,
              @Inject(APP_BASE_HREF) public baseHref: string) {
    this.productFilterList = [
      {id: 1, name: 'Underskirts', code: 'UNDERSKIRT'},
      {id: 2, name: 'Belts', code: 'BELT'},
      {id: 3, name: 'Veils', code: 'VEIL'},
      {id: 4, name: 'Hair Piece', code: 'HAIR PIECE'}
    ];
    this.sharedService.setTitle(this.title);
    this.locale = this.localeId;
    if (this.localeId === 'en-US' ||  this.localeId === 'en-GB') {
      this.prod.locale = 'GB';
    }else {
      this.prod.locale = this.localeId;
    }
    this.code = getLocaleCurrencyCode(this.localeId);
    this.error = { error: $localize`:@@feedback.error:There has been a error!`, message: $localize`:@@feedback.error.message:Please check your data and try again.` };
  }
  ngOnInit() {
    if(this.storeCountry === 'GB' || this.storeCountry === 'IE') {
      this.removeReasonCodes = true;
    } else {
      this.removeReasonCodes = false;
    }
    const feedbackObj = this.sharedService.getFeedbackObj();
    if (!feedbackObj[0].product) {
      this.choosenProducts = [];
    }else {
      this.choosenProducts = feedbackObj[0].product;
      feedbackObj[0].product.forEach(data => {
        this.productService.addProduct(data);
      });
    }
    if (!feedbackObj[0].basket) {
      this.obj.basket = [];
    } else {
      this.obj.basket = feedbackObj[0].basket;
    }
    this.choosenProducts = feedbackObj[0].product;
    this.document.body.classList.add('product-page');
    this.sharedService.confirmCompleteText.subscribe(confirmCompleteText => this.confirmCompleteText = confirmCompleteText);
    this.sharedService.formCompleted.subscribe(formCompleted => this.formCompleted = formCompleted);
    this.customerService.currentCustomer.subscribe(customerName => this.customerName = customerName);
    this.sharedService.getReasonCodes().subscribe(res => this.reasonCodes = res);
    this.productService.getAddOnProduct().subscribe(res => this.addOnProduct = res);
    // tslint:disable-next-line:max-line-length
    this.subtotal = this.obj.basket.reduce((accumulator, current) => (parseFloat(accumulator) + parseFloat(current.Unit_Price) * current.qty), 0);
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
    this.productService.getAddOnProduct().subscribe(res => {
      this.addOnProduct = res;
      this.addOnLoading = false;
      setTimeout (() => {
        this.addOnProduct.forEach((addOn, index) => {
          addOn.qty = 0;
          this.obj.basket.forEach((basket, idx) => {
            if (addOn.Item_No + '_' + addOn.Variant_Code === basket.Item_No + '_' + basket.Variant_Code) {
              // addOn.in_basket = basket.in_basket;
              // addOn.qty = basket.qty;
             this.addOnProduct[index] = this.obj.basket[idx];
              // console.log(this.obj.basket[idx]);
              // this.obj.basket[idx] = addOn[index];
              // const currentInBasket = this.obj.basket[idx];
              // this.removeFromBasket(this.obj.basket[idx]);
              // this.addToBasket(currentInBasket);
            }
          });
        });
      }, 500);
    });
    this.productList$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // tap((a) => console.log('There', a)),
      // // ignore new term if same as previous term
      // tap((b) => console.log('here', b)),
      // @ts-ignore
      distinctUntilChanged((a, b) => JSON.stringify(a) === false),

      // switch to new search observable each time the term changes
      switchMap((term: object) => {
        // @ts-ignore
          return this.productService.searchProduct(this.prod);
      }),
    ).pipe(shareReplay());
    this.productList$.subscribe(data => {
      // this.product$ = of(data);
      this.isLocationLoading = false;
    },  () => {
      console.log('error');
    }, () => {
      console.log('complete');
    });
    console.log(this.obj.basket);
  }

  save(action, templateRef) {
    this.isSaving = true;
    if (this.sharedService.getFeedbackObj()[0].customer_email !== '') {
      if (this.sharedService.getFeedbackObj()[0].id) {
        this.sharedService.update_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
          if (data.last_id === '0') {
            this.message = data.message;
            if (action === 'leave') {
              console.log('Saved and start new!');
              // window.location.replace(this.baseHref);
            }
          }
        }, error1 => {
          console.log(error1);
          this.messageError = true;
          this.isSaving = false;
          this.message = $localize`:@@feedback.product.save.message:There has been an error saving the data`;
        }, () => {
          this.isSaving = false;
          setTimeout(() => {
            this.message = '';
          }, 5000);
          if (action === 'leave') {
            this.dialogRef = this.dialog.open(templateRef, {
              id: 'confirmSubmit',
              width: '500px'
            });
            this.dialogRef.afterClosed().subscribe((confirmed: string) => {
              if (confirmed === 'ok') {
                this.sharedService.skip = true;
                window.location.replace(this.baseHref);
              } else {
                this.sharedService.skip = false;
              }
            });
          }
        });
      } else {
        this.sharedService.create_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
          if (data.last_id !== '0') {
            this.sharedService.setFeedbackMiscObj('id', data.last_id);
            this.message = data.message;
            if (action === 'leave') {
              console.log('Saved and start new!');
              window.location.replace(this.baseHref);
            }
          }
        }, error1 => {
          console.log(error1);
          this.messageError = true;
          this.isSaving = false;
          this.message = $localize`:@@feedback.product.save.message:There has been an error saving the data`;
        }, () => {
          this.isSaving = false;
          setTimeout(() => {
            this.message = '';
          }, 5000);
          if (action === 'leave') {
            this.dialogRef = this.dialog.open(templateRef, {
              id: 'confirmSubmit',
              width: '500px'
            });
            this.dialogRef.afterClosed().subscribe((confirmed: string) => {
              if (confirmed === 'ok') {
                this.sharedService.skip = true;
                window.location.replace(this.baseHref);
              } else {
                this.sharedService.skip = false;
              }
            });
          }
        });
      }
    } else {
      this.messageError = true;
      this.isSaving = false;
      this.message = $localize`:@@feedback.product.save.error.email.required:This feature is not available, email is a required field`;
    }
  }

  onFeedbackSubmit(formData, product, $event) {
    console.log('For form submission');
  }

  toggle(product, choosenProducts) {
    choosenProducts.forEach((productSingle) => {
      // tslint:disable-next-line:triple-equals
      if (productSingle != product) {
        productSingle.show = false;
      } else {
        productSingle.show = !productSingle.show;
      }
    });
  }

  searchProducts(term: string): void {
    if (term.length >= 2) {
      this.hideList = false;
      this.searchTerms.next(this.prod);
      this.isLocationLoading = true;
    } else {
      this.hideList = true;
      // this.product$ = of([]);
    }
  }

  removeProduct(i) {
    // this.removeFromBasket(this.choosenProducts[i]);
    this.productService.removeProduct(i);
    // this.sharedService.removeFeedbackBasketObj(i);
  }

  openModalReason(templateRef, confirmReasonCodeTemplateRef) {
    if (this.obj.purchased === 'no' && this.obj.reason !== ''){
      this.dialogRef = this.dialog.open(templateRef, {
        id: 'confirmSubmit',
        width: '500px'
      });
    } else {
      this.dialogRef = this.dialog.open(confirmReasonCodeTemplateRef, {
        id: 'confirmReasonCode',
        width: '500px'
      });
    }
  }

  confirmReasonCodeSubmit(templateRef) {
    if (this.obj.purchased === 'yes') {
      this.dialogRef.close(true);
      this.reasonCodeError = false;
      this.dialogRef = this.dialog.open(templateRef, {
        id: 'confirmSubmit',
        width: '500px'
      });
    } else {
      if (this.obj.reason === '') {
        this.reasonCodeError = true;
      } else {
        this.reasonCodeError = false;
        this.dialogRef.close(true);
        this.dialogRef = this.dialog.open(templateRef, {
          id: 'confirmSubmit',
          width: '500px'
        });
      }
    }
  }

  openModalNoRating(tempRef) {
    this.filterReasonType = 'CCFANF';
    this.obj.reason = '';
    this.isLocationLoading = true;
    this.sharedService.getReasonCodes(this.filterReasonType).subscribe(res => {
      this.reasonCodes = res;
      this.dialogRef = this.dialog.open(tempRef);
      this.dialogRef.afterClosed().subscribe(data => {
        this.obj.reason = '';
      });
    }, () => {
      this.isLocationLoading = false;
    }, () => {
      this.isLocationLoading = false;
    });
  }

  completeWithNoRating(tempRef) {
    this.sharedService.setFeedbackMiscObj('reason', this.obj.reason);
    if (this.removeReasonCodes) {
      this.sharedService.setFeedbackMiscObj('reason', 'CFFANFOTHE');
    }
    if (this.obj.comments) {
      this.sharedService.setFeedbackMiscObj('comments', this.obj.comments);
    }
    // const payload = this.sharedService.getFeedbackObj();
    this.sharedService.send(this.sharedService.getFeedbackObj()).subscribe(response => {
      this.sharedService.setNavResponse((response));
      if (response.error) {
        this.messageError = true;
        this.message = response.message;
      } else {
        this.messageError = false;
        this.message = '';
        this.sharedService.delete_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
          if (!data.error) {
            console.log('send to complete');
            this.router.navigateByUrl('/complete');
          }
        }, error => {
          console.log(error);
          this.messageError = true;
          this.isSaving = false;
          this.message = $localize`:@@feedback.product.save.message:There has been an error saving the data`;
          // has errors
        }, () => {
          this.dialogRef.close(true);
          this.isSaving = false;
        });
        this.sharedService.setFormCompleted(true);
        if (this.i18nObj) {
          this.sharedService.setConfirmCompleteText(this.i18nObj.product.confirmCompleteText.finish);
          // this.router.navigateByUrl('/complete');
        } else {
          this.sharedService.setConfirmCompleteText($localize`:@@feedback.thank-you:Thank You`);
          // this.router.navigateByUrl('/complete');
        }
      }
    });
  }
  openModal(templateRef) {
    const error = this.error;
    // tslint:disable-next-line:prefer-const one-variable-per-declaration
    let selectedProducts = this.choosenProducts,
      hasError = false;
    // tslint:disable-next-line:no-shadowed-variable
    selectedProducts.forEach((element) => {
      if (element.Attribute_Set === 'WED2B' && !element.starRating) {
        element.error = true;
        this.error = { error: $localize`:@@feedback.error:There has been a error!`, message: $localize`:@@feedback.error.message:Please check your data and try again.` };
        hasError = true;
      }else {
        element.error = false;
        // hasError = false;
      }
    });
    if (!hasError) {
        console.log('NO error');
        this.messageError = false;
        if (this.filterReasonType === 'CCFANF') {
          this.filterReasonType = 'CCFA';
          this.isLocationLoading = true;
          this.sharedService.getReasonCodes(this.filterReasonType).subscribe(res => {
            this.reasonCodes = res;
          }, () => {
            this.filterReasonType = 'CCFA';
            this.isLocationLoading = false;
          }, () => {
            this.filterReasonType = 'CCFA';
            this.isLocationLoading = false;
            this.dialogRef = this.dialog.open(templateRef, {
              id: 'confirmSubmit',
              width: '500px'
            });
          });
        } else {
          this.dialogRef = this.dialog.open(templateRef, {
            id: 'confirmSubmit',
            width: '500px'
          });
        }
    } else {
      this.messageError = true;
      this.message = 'Please rate your product!';
      setTimeout(() => {
        this.message = '';
        this.messageError = false;
      }, 5000);
    }
  }

  confirmComplete(choosenProducts) {
    // this.isSaving = true;
    this.dialogRef.close(true);
    this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        if (this.obj.comments) {
          this.sharedService.setFeedbackMiscObj('comments', this.obj.comments);
        }
        // const payload = this.sharedService.getFeedbackObj();
        this.sharedService.send(this.sharedService.getFeedbackObj()).subscribe(response => {
         this.sharedService.setNavResponse((response));
         if (response.error) {
           this.messageError = true;
           this.message = response.message;
         } else {
           this.messageError = false;
           this.message = '';
           this.sharedService.delete_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
             if (!data.error) {
               console.log('send to complete');
               this.router.navigateByUrl('/complete');
             }
           }, error => {
             console.log(error);
             this.messageError = true;
             this.isSaving = false;
             this.message = $localize`:@@feedback.product.save.message:There has been an error saving the data`;
             // has errors
           }, () => {
             this.isSaving = false;
           });
           this.sharedService.setFormCompleted(true);
           if (this.i18nObj) {
             this.sharedService.setConfirmCompleteText(this.i18nObj.product.confirmCompleteText.finish);
             // this.router.navigateByUrl('/complete');
           } else {
             this.sharedService.setConfirmCompleteText($localize`:@@feedback.thank-you:Thank You`);
             // this.router.navigateByUrl('/complete');
           }
         }
        });
      }
    });
  }

  feedbackObj(obj, product) {
    return {
      feedback: {
        entity_id: product.entity_id,
        employee_id: obj.fitter[0].ID,
        customer_id: obj.customer[0].No,
        product_id: product.Item_No,
        store_code: localStorage.getItem('storeCode') ?? 'undefined',
        locale: this.localeId,
        name: product.Name,
        description: product.Description,
        price: product.Unit_Price,
        feedback: product.feedback,
        purchase: obj.customer[0].Purchase,
        visit: obj.customer[0].No_of_Store_Visits
      }
    };
  }

  onAddOnchange(ev, item, obj) {
    console.log(item);
    if (ev.checked) {
      this.addToBasket(item);
    }else {
      this.removeFromBasket(item, obj);
    }
  }

  onChange(ev) {
    if (ev.value === 'yes') {
      this.obj.reason = '';
      this.obj.comments = '';
      this.reasonCodeError = false;
    }
    this.sharedService.setFeedbackMiscObj('reason', this.obj.reason);
    this.sharedService.setFeedbackMiscObj('purchased', ev.value);
  }

  plus(index) {
    if (this.obj.basket[index].qty < 10) {
      this.obj.basket[index].qty = this.obj.basket[index].qty + 1;
      this.subtotal = parseFloat(this.obj.basket[index].Unit_Price) + this.subtotal;
    }
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
  }
  minus(index) {
    if (this.obj.basket[index].qty !== 1) {
      this.obj.basket[index].qty = this.obj.basket[index].qty - 1;
      this.subtotal = this.subtotal - parseFloat(this.obj.basket[index].Unit_Price);
    }
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
  }
  AddOnPlus(index) {
    /*
    TODO get DRY
     */
    if (this.addOnProduct[index].qty < 10) {
      this.addOnProduct[index].qty = this.addOnProduct[index].qty + 1;
      this.addToBasket(this.addOnProduct[index]);
      this.addOnProduct[index].in_basket = true;
      // this.subtotal = parseFloat(this.addOnProduct[index].Unit_Price) + this.subtotal;
    }
  }
  AddOnMinus(index) {
    /*
    TODO get DRY
     */
    if (this.addOnProduct[index].qty !== 0) {
      this.addOnProduct[index].qty = this.addOnProduct[index].qty - 1;
      this.subtotal = this.subtotal - parseFloat(this.addOnProduct[index].Unit_Price);
    }
    if (this.addOnProduct[index].qty === 0) {
      this.removeFromBasket(this.addOnProduct[index], this.addOnProduct);
      this.addOnProduct[index].in_basket = false;
    }
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
  }

  AllProductPlus(index) {
    /*
    TODO get DRY
     */
    if (this.allProductList[index].qty < 10) {
      this.allProductList[index].qty = this.allProductList[index].qty + 1;
      this.addToBasket(this.allProductList[index]);
      this.allProductList[index].in_basket = true;
      // this.subtotal = parseFloat(this.addOnProduct[index].Unit_Price) + this.subtotal;
    }
  }
  AllProductMinus(index) {
    /*
    TODO get DRY
     */
    if (this.allProductList[index].qty !== 0) {
      this.allProductList[index].qty = this.allProductList[index].qty - 1;
      this.subtotal = this.subtotal - parseFloat(this.allProductList[index].Unit_Price);
    }
    if (this.allProductList[index].qty === 0) {
      this.removeFromBasket(this.allProductList[index], this.allProductList);
      this.allProductList[index].in_basket = false;
    }
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
  }

  createPurchaseOrder() {
    // TODO
  }

  editCart() {
    this.enableEditCart = ! this.enableEditCart;
  }

  strReplace(value: string): string {
    return value.replace(/http:\/\/w2b-ims/g, 'https://w2bcwa.blob.core.windows.net');
  }

  radioCodeChange(ev) {
    // console.log(this.obj);
    if (ev.value) {
      this.reasonCodeError = false;
      this.sharedService.setFeedbackMiscObj('reason', ev.value);
      // this.sharedService.setFeedbackMiscObj('comments', this.obj.comments); // TODO KEYUP TEXTAREA
    }
    if (ev.value === 'OTHER') {
      this.sharedService.setFeedbackMiscObj('comments', this.obj.comments); // set comments property
    }
  }

  onStarRateChange(ev, prod) {
    if (!isNaN(ev)) {
      prod.error = false;
    }
  }

  addToBasket(product) {
    console.log('added');
    if (product.qty === undefined || product.qty === 0) {
      product.qty = 1;
    }else {
      product.qty = product.qty;
    }
    product.in_basket = true;
    if (!this.obj.basket) {
      console.log('here');
      this.obj.basket = [];
    }
    // tslint:disable-next-line:no-shadowed-variable
    // @ts-ignore
    // tslint:disable-next-line:no-shadowed-variable max-line-length
    const basketObj = this.obj.basket.find(obj => obj.Item_No + '_' + obj.Variant_Code === product.Item_No + '_' + product.Variant_Code);
    console.log(basketObj);
    if (basketObj === undefined) {
      this.sharedService.setFeedbackBasketObj(product);
      this.obj.basket = this.sharedService.getFeedbackObj()[0].basket;
      console.log(this.obj.basket);
      // tslint:disable-next-line:max-line-length
    }
    this.hideList = true;
    // tslint:disable-next-line:max-line-length
    this.subtotal = this.obj.basket.reduce((accumulator, current) => (parseFloat(accumulator) + parseFloat(current.Unit_Price) * current.qty), 0);
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
    console.log(this.itemInBasket);
  }

  removeFromBasket(product, obj) {
    if (obj) {
      obj.forEach((item, index) => {
        if (item.Item_No + '_' + item.Variant_Code === product.Item_No + '_' + product.Variant_Code) {
          // tslint:disable-next-line:no-shadowed-variable
          const removeIndex = this.obj.basket.findIndex( basket => basket.Variant_Code === product.Variant_Code );
          this.obj.basket.splice( removeIndex, 1 );
          item.in_basket = false;
          item.qty = 0;
        }
      });
    }
    // tslint:disable-next-line:max-line-length
    this.subtotal = this.obj.basket.reduce((accumulator, current) => (parseFloat(accumulator) + parseFloat(current.Unit_Price) * current.qty), 0);
    this.itemInBasket = this.obj.basket.reduce((accumulator, current) => accumulator + current.qty, 0);
  }

  clearSearch(value) {
   // @ts-ignore
    this.hideList = true;
    value.value = '';
    // value.focus();
    this.prod.id = '';
    this.searchTerms.next(this.prod);
  }

  allProductFilterChange(e) {
    // @ts-ignore
    this.prod.product_group_code = e.value;
    this.isLocationLoading = true;
    this.productService.searchProduct(this.prod).subscribe( data => {
      this.allProductList = data;
      this.allProductList.forEach((v, i ) => {
        this.allProductList[i].qty = 0;
        this.allProductList[i].in_basket = false;
        this.obj.basket.forEach((item, index) => {
          if (item.Item_No + '_' + item.Variant_Code === v.Item_No + '_' + v.Variant_Code) {
            // @ts-ignore
            this.allProductList[i].qty = item.qty;
            // @ts-ignore
            this.allProductList[i].in_basket = item.in_basket;
          }
        });
      });
      // @ts-ignore
      this.prod.product_group_code = undefined;
    }, error => {
      console.log('error');
    }, () => {
      console.log('complete');
      this.isLocationLoading = false;
    });
    // console.log(e.value); // Do http request get product filter by category
  }

}
