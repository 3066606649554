<div class="container">
  <header>
    <div (click)="menuOpen=!menuOpen" [ngClass]="menuOpen ? 'active-icon' : ''" class="burger-icon" *ngIf="isMobile">
<!--      <div class="hamburger hamburger-icon"></div>-->
      <mat-icon *ngIf="!menuOpen" aria-hidden="false" aria-label="menu icon">menu</mat-icon>
      <mat-icon *ngIf="menuOpen" aria-hidden="false" aria-label="menu icon">menu_open</mat-icon>
      <img class="logo" src="assets/img/wed2b-logo.svg" width="90">
    </div>
    <div *ngIf="menuOpen || !isMobile" class="users-wrapper" [ngClass]="{'mobile' : isMobile, 'open' : menuOpen}">
      <span class="advisor-block" *ngIf="fitterName"><a routerLink="/dashboard" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}">{{ fitterName | uppercase }}</a></span>
      <span class="customer-block" *ngIf="customerName"><a routerLink="/customer" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}">{{ customerName | uppercase }}</a></span>
    </div>
    <div id="language-switcher">
      <span class="active-lng"><img src="{{locale.image}}" alt="image" (click)="$event.stopPropagation()" (click)="toggleSwitcher($event)"></span>
      <div class="language-list-wrapper" [ngClass]="status ? 'open' : 'close'">
        <ul class="language-list">
          <li *ngFor="let language of languageList">
            <a href="{{ language.baseHref }}" *ngIf="language.code !== locale.code">
              <img src="{{ language.image }}" alt="image"><span>{{ language.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <span class="clock">{{ myDate | date:'EEE d LLL yy' }} - {{ myTime | date:'HH:mm:ss' }}</span>
  </header>
  <nav>
    <div class="inner-nav">
      <a routerLink="/dashboard" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}"><mat-icon aria-hidden="false" aria-label="home icon">home</mat-icon></a>
      <a routerLink="/customer" routerLinkActive="active" [ngClass]="{'disabled' : !fitterName || formCompleted}"><mat-icon aria-hidden="false" aria-label="person icon">person</mat-icon></a>
      <a routerLink="/product" routerLinkActive="active" [ngClass]="{'disabled' : !customerName || formCompleted}"><mat-icon aria-hidden="false" aria-label="list icon">format_list_bulleted</mat-icon></a>
      <a routerLink="/complete" routerLinkActive="active" [ngClass]="{'disabled' : !formCompleted, 'completed': formCompleted}"><mat-icon aria-hidden="false" aria-label="check icon">check</mat-icon></a>
    </div>
  </nav>
  <h1>{{ title }}
    <span [hidden]="true" class="service-icon-wrapper shortcut">
      <mat-icon class="mat-icon material-symbols-outlined">person_add</mat-icon>
    </span>
  </h1>
  <div class="main-contents-outer">
    <div class="main-contents">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <div id="support-container" (click)="toggleSupport()">
      <div class="support-icon-wrapper">
        <span class="material-symbols-outlined">help</span>
<!--        <mat-icon aria-hidden="false" aria-label="support icon">help</mat-icon>-->
        <small i18n="@@feedback.support">Support</small>
      </div>
      <div class="support-links" *ngIf="showSupport">
        <a class="disabled-link" routerLink="/training-material" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}"><mat-icon matSuffix>video_library</mat-icon>&nbsp;<span i18n="@@feedback.support.training">Training</span></a>
        <a routerLink="/request-form" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}"><mat-icon matSuffix>newspaper</mat-icon>&nbsp;<span i18n="@@feedback.support.request.form">Request Form</span></a>
        <a class="disabled-link" routerLink="/request-form" routerLinkActive="active" [ngClass]="{'disabled' : formCompleted}"><mat-icon matSuffix>local_library</mat-icon>&nbsp;<span i18n="@@feedback.support.knowledge.base">Knowledge Base</span></a>
      </div>
    </div>
    <div id="store-location-container">
      <span class="store-country-location">{{ storeCountry }}</span>
      <mat-divider class="divider" [vertical]="true"></mat-divider>
      <span class="store-location" [appSpinner]="isLocationLoading" (click)="resetCount()">{{ storeName }}</span>
    </div>
    <a i18n="@@feedback.website.url">www.wed2b.com</a>
  </footer>
</div>
