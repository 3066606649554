import {AfterViewInit, ViewChild, Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SharedService} from '../shared.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {element} from 'protractor';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FeedbackListComponent implements OnInit, AfterViewInit, OnDestroy {
  title = $localize`:@@feedback.feedback.list.incomplete:Incomplete Feedbacks`;
  displayedColumns: string[] = ['created_at', 'fitter', 'customer', 'send'];
  hasData = true;
  isLoadingResults = true;
  public dataSource = new MatTableDataSource([]);
  public  expandedElement: [] | null;
  @ViewChild(MatSort) sort: MatSort;
  constructor(@Inject(DOCUMENT) private document: Document, private sharedService: SharedService) { }

  ngAfterViewInit() {
    this.sharedService.get_tmp().subscribe(data => {
      if (data !== null || data !== []) {
        const filterValue = localStorage.getItem('storeCode');
        // @ts-ignore
        if (filterValue && filterValue !== 'undefined') {
          this.dataSource = data.filter(feedback => feedback.store_code === filterValue);
        } else {
          this.dataSource = data;
        }
      }
      // @ts-ignore
      this.dataSource = new MatTableDataSource(this.dataSource);
      this.dataSource.sort = this.sort;
      this.isLoadingResults = false;
      this.dataSource.data.forEach((val, index) => {
        val.product.forEach((v, i) => {
          if (v.starRating > 0) {
            this.dataSource.data[index].product[i].hasStarRating = true;
          }
        });
      });
      console.log(this.dataSource.data);
      if (this.dataSource.data.length === 0) {
          this.hasData = false;
      }
    });
  }

  ngOnDestroy() {
    this.document.body.classList.remove('feedback-list');
  }
  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.document.body.classList.add('feedback-list');
  }
  getStarRating(data) {
    data.hide = false;
    console.log(data);
  }

  sendFeedback(data, event) {
    if (!data.complete && data) {
      this.isLoadingResults = true;
      const payload = [];
      payload.push(data);

      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      const hasStar = payload[0].product.find(obj => obj.Attribute_Set === 'WED2B' && obj.starRating === 0);
      console.log(payload);
      console.log(hasStar);
      if (hasStar === undefined) {
        event.stopPropagation();
        this.sharedService.send(payload).subscribe(response => {
          if (!response.error) {
            this.sharedService.delete_tmp(payload[0]).subscribe(resp => {
              if (!data.error) {
                console.log('send to complete');
              }
            }, error => {
              console.log(error);
              // has errors
            }, () => {
              console.log('complete');
              data.complete = true;
              this.isLoadingResults = false;
            });
          }
        });
      } else {
        console.log('rate product');
        this.isLoadingResults = false;
      }
    }
  }
}
