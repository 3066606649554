<div class="request-form-container">
  <form [formGroup]="requestForm" #requestFormDirective (ngSubmit)="onSubmit(requestFormDirective)">
    <div>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@feedback.request.form.name.label">Request name</mat-label>
          <input matInput placeholder="Request name" formControlName="name" >
          <mat-icon matSuffix>note</mat-icon>
          <mat-hint i18n="@@feedback.request.form.title.hint">Give a brief name to your request</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@feedback.request.form.choose-category.label">Choose a category</mat-label>
          <mat-select #select formControlName="category">
            <mat-select-trigger *ngIf="select.value">
              <mat-icon>{{ select.value.icon }}</mat-icon>&nbsp;{{ select.value.name }}
            </mat-select-trigger>
            <mat-option value="null" [disabled]="'true'" i18n="@@feedback.request.form.choose-category.label">Choose a category</mat-option>
            <mat-option *ngFor="let category of categories" [value]="category">
              <mat-icon class="category-icon" matSuffix>{{category.icon}}</mat-icon>
              {{category.name}}
            </mat-option>
          </mat-select>
          <mat-icon class="category-icon" matSuffix>category</mat-icon>
          <mat-hint i18n="@@feedback.request.form.choose-category.hint">Please select a category from the following to describe your feature request</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@feedback.request.form.description">Detailed description</mat-label>
          <textarea matInput placeholder="Detailed description" formControlName="description" rows="5"></textarea>
          <mat-icon matSuffix>description</mat-icon>
          <mat-hint i18n="@@feedback.request.form.description.hint">Provide a full description. Try to be as detailed as possible about how this would help solve your pains, along with your requirements</mat-hint>
        </mat-form-field>
      </p>
      <br/>
      <p class="file-upload-wrapper">
        <span class="file-limit-reached-msg" *ngIf="attachments.length === 4"><span class="material-symbols-outlined limit-icon">warning</span>You've reached file upload limit</span>
        <mat-form-field appearance="fill" (click)="f_input.click()" [ngClass]="attachments.length === 4 ? 'file-upload-disabled' : ''">
          <mat-label i18n="@@feedback.request.form.file-upload.label">No file chosen</mat-label>
          <input type="text" readonly matInput formControlName="fileName" />
          <input type="file" multiple hidden #f_input (change)="detectFiles($event)" accept="image/x-png,image/jpeg"/>
          <mat-icon matSuffix>attach_file</mat-icon>
          <mat-hint i18n="@@feedback.request.form.file-upload.hint">If you have any attachments you want to share e.g. a mock-up of the feature you are requesting, bug issues, screen shots etc...</mat-hint>
        </mat-form-field>
      </p>
    </div>
    <br/>
    <div class="images-preview" *ngIf="attachments.length">
      <div class="row">
        <div class="attachment-wrapper" formArrayName="attachments" *ngFor="let attachment of attachments.controls; let i = index;">
          <div class="card mb-3" [formGroupName]="i">
            <button type="button" class="btn btn-danger remove-btn" (click)="removeAttachment(i)"><mat-icon matSuffix>delete</mat-icon></button>
            <img [src]="attachment.controls['url'].value" class="card-img-top" alt="Image Preview">
          </div>
        </div>
      </div>
    </div>
    <fieldset formGroupName="requestedBy">
      <legend i18n="@@feedback.request.form.requestedBy.legend">Requested By</legend>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@feedback.request.form.requestedBy.name.label">Name</mat-label>
          <input matInput placeholder="Name" formControlName="name" >
          <mat-icon matSuffix>badge</mat-icon>
          <mat-hint i18n="@@feedback.request.form.requestedBy.name.hint">Please provide your Name</mat-hint>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@feedback.request.form.requestedBy.email.label">Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
          <mat-icon matSuffix>contact_mail</mat-icon>
          <mat-hint i18n="@@feedback.request.form.requestedBy.email.hint">Please provide your Email</mat-hint>
        </mat-form-field>
      </p>
    </fieldset>
    <p class="note" i18n="@@feedback.request.form.note">Complete the form to enable submit.</p>
    <button class="submit-request" mat-raised-button type="submit" [disabled]="!requestForm.valid || loading">
      <span [hidden]="loading" i18n="@@feedback.request.form.submit">Submit</span>
      <div *ngIf="loading" class="spinner-outer-wrapper">
        <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
        <div class="spinner-logo-wrapper">
          <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
        </div>
      </div>
    </button>
  </form>
</div>
