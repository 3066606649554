// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://dev.cffa.wed2b.com',
  apiFitterUrl: 'https://dev.cffa.wed2b.com/api/fitter-search',
  apiCustomerUrl: 'https://dev.cffa.wed2b.com/api/customer-search',
  apiProductUrl: 'https://dev.cffa.wed2b.com/api/product-search',
  apiAddonProductUrl: 'https://dev.cffa.wed2b.com/api/get-addon-product',
  apiStoreUrl: 'https://dev.cffa.wed2b.com/api/get-store',
  apiGetReasonCode: 'https://dev.cffa.wed2b.com/api/get-reason-code',
  rest: {
    createUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/create',
    createTmpUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/create_tmp',
    updateUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/update',
    updateTmpUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/update_tmp',
    getUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/get',
    getTmpUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/get_tmp',
    deleteTmpUrl: 'https://dev.cffa.wed2b.com/api/rest/feedback/delete_tmp',
    getStoreLocation: 'https://dev.cffa.wed2b.com/api/rest/feedback/get-store-location',
    send: 'https://dev.cffa.wed2b.com/api/rest/feedback/send',
    submitRequestForm: 'https://dev.cffa.wed2b.com/api/rest/feedback/submit-request-form'
  },
  scandit: {
    licenseKey: 'ASBRhC9LC1P5OGkunx9rGb8Mj8O7EWtR715HyKZmIzMJdEzoHU+c42trq0ZoTs7CCzPfBU0Cl9y8KZTyYWk07ZZW75myXe5vB10sAh5soNR0c4tcmi9jhf1oZm3mNvwOAHcTNpRUCJtWXTeETG33cThCaGlNH81/8BwDGxojR/uMOAoldlwrUtULhOwlMKqs1WPIEqrG/j78/Q1ENbqAIFkMSg75w4ejl92aSoBu/X7QOz+1Qlv7cQTkxPTmpp8CpiZgFwlWY228ZE/fsHSAqrw9dHdv0Sitjqsm+3BS8qft0sWdoWgdIANk/yUpVXXJlsX4azKemlyeTguQvnOa+tn3H0ug+KHvTy0C4OTEQI9qIcl6mZIHYUYwb0qJrucgKDMV8VbeaoqqUXDZGGsvRDZDMsSmbvtwRF2jK9c86NO/+1kmhir/6MDbRi6E2qGNV6DtsJot1xB2ZrdBNRoUTwp8/9mT9m5H515zmg1jnCTr3YtHyOsm/BBW17XMnLcO/yKol0Zotlf5aZGCBEijuczT3Z+2QbpDn/R6wT1oK0vyOCFTogDNlj1pZsM3vtg2ymD3RYnswDSkUiagGwCs8zbfUTAOB8OyoCyU0eYLEy3B6iWE2JI1+1Xz4G5n+mnMSGNUGQj3/gCkE5tQrEzip/jArxpZh9kqEk+vf4/TOUu0qy0Vlbm5c7GgQ7NVT+a0f03azKtorlsMLdiZ6sYY0v1gw6tydjQHl8kZp4r/lfz8f0YMK/SIAxxIX/1un2ZOLyqZz9fqTZBopDL2t+byUhiRWxjUAKL8sPH4Qsn4xyMp5ONRsmXemq8TZg3xNzW+c7NqBxq6DobNTy3hJdk=',
    engineLocation: 'assets/'
  },
  qr_code_generator: {
    url: 'https://api.qr-code-generator.com/v1/',
    access_token: 'QwGciZeJqDnCS2EDQrXsJBKJrHV9EVM_B8O9oeW5SwvukuyE8DuRYrYoGWd04YUL',
    format: {
      image_format: '&image_format=SVG',
      image_width: '&image_width=250',
      frame_name: '&frame_name=bottom-frame',
      frame_text: '&frame_text=Review',
      frame_icon_name: '&frame_icon_name=feedback',
      qr_code_logo: '&qr_code_logo=scan-me-square',
      frame_color: '&frame_color=%23a98647',
      foreground_color: '&foreground_color=%23a98647',
      marker_left_template: '&marker_left_template=version8',
      marker_right_template: '&marker_right_template=version7',
      marker_bottom_template: '&marker_bottom_template=version6'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
