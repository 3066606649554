import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged, shareReplay, switchMap, tap} from 'rxjs/operators';

import {Customer} from '../customer';
import {CustomerService} from '../customer.service';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.less']
})
export class CustomerSearchComponent implements OnInit, AfterViewInit {
  // @ts-ignore
  @ViewChild('searchCustomerBox') nameField: ElementRef;
  formCompleted: boolean;
  hideList = true;
  showSearch = false;
  selectedEmail = '';
  customerName: string;
  visible = false;
  shoInfo = false;
  customers$: Observable <Customer[]>;
  isLocationLoading = false;
  isLocationLoadingCustomerList = false;
  private searchTerms = new Subject<string>();
  constructor(
    private customerService: CustomerService,
    private sharedService: SharedService,
    private router: Router) {}

  ngOnInit() {
    this.sharedService.formCompleted.subscribe(formCompleted => this.formCompleted = formCompleted);
    this.customerService.currentCustomer.subscribe(customerName => this.customerName = customerName);
    this.customerService.currentEmailCustomer.subscribe(selectedEmail => this.selectedEmail = selectedEmail);
    this.customers$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      // @ts-ignore
      distinctUntilChanged((a, b) => JSON.stringify(a) === false),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.customerService.searchCustomers(term)),
    ).pipe(shareReplay());
    this.customers$.subscribe( data => {
      this.isLocationLoading = false; // remove spinner
      this.isLocationLoadingCustomerList = false; // remove spinner
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getCustomerList();
    }, 10);
  }

  focusInput(): void {
    this.showSearch = !this.showSearch;
    if (this.showSearch) {
      setTimeout(() => {
        this.nameField.nativeElement.focus();
      }, 200);
    }
  }

  searchCustomers(term: string): void {
    if (term.length > 5) {
      this.searchTerms.next(term);
      this.hideList = false;
      this.isLocationLoading = true;
    }
  }

  getCustomerList() {
    this.isLocationLoadingCustomerList = true; // remove spinner
    this.searchTerms.next('fullList');
  }

  getCustomer(item: any): void {
    console.log(item);
    // @ts-ignore
    const fitter = this.sharedService.getFeedbackObj()[0].fitter;
    this.sharedService.get_tmp(item.E_Mail).subscribe(data => {
      if (data.length > 0) {
        this.sharedService.resetFeedbackObj();
        data[0].fitter = fitter;
        this.sharedService.setFeedbackObj(data[0]);
        // this.sharedService.setFeedbackFitterObj(fitter);
      }else {
        this.sharedService.resetFeedbackObj();
        this.sharedService.setFeedbackObj(
          { purchased: 'no',
            reason: '',
            // comments: '',
            fitter,
            customer: [],
            locale: this.sharedService.getLocaleId(),
            store_code: this.sharedService.getStoreCode()
          }
        );
        this.sharedService.setFeedbackCustomerObj(item);
      }
    },
      error => {
      console.log('error');
      },
      () => { // when complete
      if (this.customerName) {
        // this.router.navigateByUrl('/product');
        console.log('completed');
        this.customerService.addCustomer(item);
      }
      });
    this.customerService.getCustomerEmail(item.E_Mail);
    this.customerService.getCustomerName(item.Name);
    this.hideList = true;
    // @ts-ignore
    this.searchTerms.next('');
  }

  onClick() {
    this.visible = ! this.visible;
  }

  toggleInfo() {
    this.shoInfo = ! this.shoInfo;
  }

}
