import {Component, ElementRef, OnInit, Inject, LOCALE_ID, ViewChild, AfterViewInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import {MatDialog} from '@angular/material/dialog';
import {Product} from '../product';
import {ProductService} from '../product.service';
import { SharedService } from '../shared.service';
import { ScanSettings, Barcode, CameraAccess, CameraSettings, Camera, BarcodePicker } from 'scandit-sdk-angular';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.less']
})
export class ProductSearchComponent implements OnInit, AfterViewInit {
  public settings = new ScanSettings({ enabledSymbologies: [Barcode.Symbology.CODE128] });
  public cameraType = Camera.Type.BACK;
  public cameraSettings = {resolutionPreference: CameraSettings.ResolutionPreference.FULL_HD};
  // @ts-ignore
  @ViewChild('searchProductBox') nameField: ElementRef;
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;
  choosenProducts: Array<any>;
  activeScanner = false;
  barcodeButtonDisabled = false;
  selectedCamera: object;
  barcodeValue;
  hideList = true;
  productName: string;
  camera = new Subject<object>();
  camera$: Observable<any> = this.camera.asObservable();
  prod = {
    id: null,
    barcodeValue: null,
    locale: null,
    attribute_set: 'WED2B',
  };
  product$: Observable<Product[]>;
  formCompleted: boolean;
  isLocationLoading = false;
  private searchTerms = new Subject<object>();
  constructor(
    private productService: ProductService,
    private sharedService: SharedService,
    @Inject(LOCALE_ID) protected localeId: string,
    public dialog: MatDialog) {
    if (this.localeId === 'en-US' ||  this.localeId === 'en-GB') {
        this.prod.locale = 'GB';
    }else {
      this.prod.locale = this.localeId;
    }
    // const d = CameraAccess.getCameras().then((data) => { this.camera.next(data); });
    // console.log(this.camera$.subscribe(data => console.log(data)));
  }

  productSearchBlur() {
    setTimeout(  () => {
      this.hideList = true;
    }, 300);
  }

  ngAfterViewInit() {
    // this.barcodeScanner.start();
  }

  searchProducts(term: string): void {
    if (term.length >= 2) {
      this.hideList = false;
      this.searchTerms.next(this.prod);
      this.isLocationLoading = true;
    } else {
      this.hideList = true;
      this.isLocationLoading = false;
      // this.product$ = of([]);
    }
  }

  getProductLength() {
    return this.productService.getProductLength();
  }

  getProductItem(item: any): void {
    this.hideList = false;
    this.isLocationLoading = false;
    if (!this.formCompleted) {
      // if (this.getProductLength() < 4) {
      let match;
      // tslint:disable-next-line:max-line-length
      if (this.sharedService.getFeedbackObj()[0].product || this.choosenProducts) {
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          match = this.sharedService.getFeedbackObj()[0].product.find(obj => obj.Item_No + '_' + obj.Variant_Code === item.Item_No + '_' + item.Variant_Code);
      }
      if (match === undefined) {
            this.productService.addProduct(item);
            this.sharedService.setFeedbackProductObj(item);
            this.choosenProducts = this.sharedService.getFeedbackObj()[0].product;
          }
      this.prod.barcodeValue = null;
      // } else {
      //   alert($localize`:@@feedback.alert.limit:You have reached your limit of dresses`);
      // }
    }
    this.hideList = true;
    this.nameField.nativeElement.value = '';
    this.prod.id = null;
    this.prod.barcodeValue = null;
  }

  ngOnInit() {
    const feedbackObj = this.sharedService.getFeedbackObj();
    this.sharedService.formCompleted.subscribe(formCompleted => this.formCompleted = formCompleted);
    this.product$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // tap((a) => console.log('There', a)),
      // // ignore new term if same as previous term
      // tap((b) => console.log('here', b)),
      // @ts-ignore
      distinctUntilChanged((a, b) => JSON.stringify(a) === false),

      // switch to new search observable each time the term changes
      switchMap((term: object) => {
        // @ts-ignore
        if (term.id && term.id.length >= 2) {
          return this.productService.searchProduct(this.prod);
        }
      }),
    ).pipe(shareReplay());
    this.product$.subscribe(data => {
      // this.product$ = of(data);
      this.isLocationLoading = false;
      setTimeout(() => {
        this.isLocationLoading = false;
      }, 300);
    },  () => {
      console.log('error');
    }, () => {
      console.log('complete');
    });
  }

  startScanner() {
    this.activeScanner = ! this.activeScanner;
    this.activeScanner ? this.barcodeScanner.start() : this.barcodeScanner.stop();
    this.prod.id = null;
    this.prod.barcodeValue = null;
    this.hideList = true;
  }

  onScan(ev: any) {
    this.prod.barcodeValue = ev.barcodes[0].data;
    this.prod.id = this.prod.barcodeValue;
    this.activeScanner = ! this.activeScanner;
    this.barcodeButtonDisabled = true;
    this.isLocationLoading = true;
    this.productService.searchProduct(this.prod).subscribe(product => {
      if (product) {
        if (product.length > 0) {
          this.getProductItem(product[0]);
        }
      }
      this.prod.id = null;
      this.prod.barcodeValue = null;
      this.barcodeButtonDisabled = false;
      this.isLocationLoading = false;
    });
  }

  errorScan(ev: any) {
    alert(ev);
  }


  onValueChanges(result: QuaggaJSResultObject ) {
    this.playSound();
    this.prod.barcodeValue = result.codeResult.code;
    console.log(result.codeResult);
    this.barcodeButtonDisabled = true;
    this.productService.searchProduct(this.prod).subscribe(product => {
      if (product.length > 0) {
        this.getProductItem(product[0]);
      }
      this.prod.id = null;
      // this.prod.barcodeValue = null;
      this.barcodeButtonDisabled = false;
    });
    this.activeScanner = false;
    this.barcodeScanner.stop();
  }

  onStarted(started: any) {
    console.log(this.barcodeScanner);
    // this.controllZoom();
    // this.lightTorch();
  }

  playSound() {
    navigator.vibrate(200);
    const audio = new Audio();
    audio.src = '../assets/beep-07a.wav';
    audio.load();
    audio.play();
  }
}
