import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) { return null; }
    if (!args) { return value; }
    if (isNaN(args)) {
      args = args.toLowerCase();;
    } else {
      args = args;
    }

    // tslint:disable-next-line:only-arrow-functions
    return value.filter(function(data) {
      if (isNaN(args)) {
        return JSON.stringify(data).toLowerCase().includes(args);
      } else {
        return JSON.stringify(data.starRating).includes(args);
      }
    });
  }

}
