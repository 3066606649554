import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { Router } from '@angular/router';
import {debounceTime, distinctUntilChanged, shareReplay, switchMap} from 'rxjs/operators';

import { Fitter } from '../fitter';
import { FitterService } from '../fitter.service';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-fitter-search',
  templateUrl: './fitter-search.component.html',
  styleUrls: ['./fitter-search.component.less']
})
export class FitterSearchComponent implements OnInit {
  // @ts-ignore
  @ViewChild('searchBox') nameField: ElementRef;
  formCompleted: boolean;
  hideList = false;
  title = $localize`:@@feedback.fitter:Consultant`;
  show = true;
  fitterName: string;
  fitters$: Observable<Fitter[]>;
  choosenFitter: Array<object>;
  isLocationLoading = false;
  private searchTerms = new Subject<string>();
  constructor(
    private fitterService: FitterService,
    private sharedService: SharedService,
    private router: Router) {}

  search(term: string): void {
    if (term.length >  2) {
      this.searchTerms.next(term);
      this.isLocationLoading = true; // show spinner
    }
  }

  getFitter(item: any): void {
    this.fitterService.addFitter(item);
    this.fitterService.getFitterName(item.First_Name);
    this.hideList = true;
    this.nameField.nativeElement.value = '';
  }

  ngOnInit(): void {
    this.sharedService.formCompleted.subscribe(formCompleted => this.formCompleted = formCompleted);
    this.fitterService.currentFitter.subscribe(fitterName => this.fitterName = fitterName);
    this.fitters$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      // @ts-ignore
      distinctUntilChanged((a, b) => JSON.stringify(a) === false),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.fitterService.searchFitters(term)),
    ).pipe(shareReplay());
    this.fitters$.subscribe( data => {
      this.isLocationLoading = false; // remove spinner
    }, (error) => {
      console.error('error caught in here');
      alert('ERROR: ' + JSON.stringify(error.error.text));
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    // child is set
    this.nameField.nativeElement.focus();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterContentInit() {

  }

  blur(e) {
    // tslint:disable-next-line:no-unused-expression
    // this.fitters$ = new Observable<Fitter[]>();
  }

}
