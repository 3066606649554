import { Directive, HostBinding, OnInit, OnChanges, Input } from '@angular/core';

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective implements OnInit, OnChanges {
  @HostBinding('class.loading')
  @Input() appSpinner: string;
  constructor() {}
  ngOnInit() {}
  ngOnChanges(){}
}
