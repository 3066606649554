import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import {environment} from '../environments/environment';
import { MessageService } from './message.service';
import { Shared } from './shared';
import {Fitter} from './fitter';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    Authorization: 'Basic ' + btoa('feedback.admin:G4M#-h6=5PJAkh6')
  })
};

const httpOptions2 = {
  headers: new HttpHeaders({
    responseType: 'text'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  get skip(): boolean {
    return this._skip;
  }

  set skip(value: boolean) {
    this._skip = value;
  }
  title = new BehaviorSubject<string>($localize`:@@feedback:Feedback`);
  title$ = this.title.asObservable();
  formCompleted = new BehaviorSubject(false);
  // tslint:disable-next-line:variable-name
  private _skip: boolean;
  confirmCompleteText = new BehaviorSubject($localize`:@@feedback.button.confirm.complete:Confirm & Complete`);
  private feedBackObj: Shared[] = [];
  private navFeedbackResponse = [];
  constructor(@Inject(LOCALE_ID) protected localeId: string,
              private http: HttpClient,
              private messageService: MessageService) {
    if (this.localeId === 'en-US') {
      this.localeId = 'en-GB';
    }
  }

  resetFeedbackObj() {
    this.feedBackObj = [];
  }

  getFeedbackFitterObj() {
    return this.getFeedbackObj()[0].fitter;
  }

  setFeedbackObj(item: Shared) {
    if (Object.entries(item).length === 0) {
      console.log('it emtpy');
      this.resetFeedbackObj();
    } else {
      this.feedBackObj.push(item);
    }
  }
  setFeedbackMiscObj(key: any, value: any) {
    this.feedBackObj[0][key] = value;
  }
  removeFeedbackProductObj(i) {
    this.feedBackObj[0].product.splice(i , 1);
  }
  setFeedbackFitterObj(fitter) {
    this.feedBackObj[0].fitter = [];
    this.feedBackObj[0].fitter.push(fitter);
  }
  setFeedbackCustomerObj(customer) {
    if (!this.feedBackObj[0].product) {
      this.feedBackObj[0].product = [];
    }
    this.feedBackObj[0].customer.push(customer);
    this.feedBackObj[0].customer_email = customer.E_Mail;
  }
  setFeedbackProductObj(product) {
    if (!this.feedBackObj[0].product) {
      this.feedBackObj[0].product = [];
    }
    this.feedBackObj[0].product.push(product);
  }
  setFeedbackBasketObj(basket) {
    if (!this.feedBackObj[0].basket) {
      this.feedBackObj[0].basket = [];
    }
    this.feedBackObj[0].basket.push(basket);
  }
  removeFeedbackBasketObj(i) {
    this.feedBackObj[0].basket.splice(i , 1);
  }

  getFeedbackObj(): Shared[] {
    return this.feedBackObj;
  }

  setTitle(title: string) {
    this.title.next(title);
  }
  get getTitle(): Observable<string> {
    return this.title$;
  }

  setQrCodeLocal(item) {
    return localStorage.setItem('qrcode', item);
  }

  getQrCodeLocal() {
    return localStorage.getItem('qrcode') ?? null;
  }

  setFormCompleted(formCompleted: boolean) {
    this.formCompleted.next(formCompleted);
  }

  setConfirmCompleteText(confirmCompleteText: string) {
    this.confirmCompleteText.next(confirmCompleteText);
  }

  setLocaleIdStorage() {
    localStorage.setItem('locale_id', this.getLocaleId());
  }

  getLocaleIdStorage() {
    return localStorage.getItem('locale_id') ?? null;
  }

  getUpdateQrCodeStorage() {
    return localStorage.getItem('updateQrcode') ?? null;
  }

  updateQrCodeStorage(value) {
    localStorage.setItem('updateQrcode', value);
  }

  getLocaleId() {
    return this.localeId;
  }
  getStoreCode() {
    return localStorage.getItem('storeCode') ?? 'undefined';
  }

  getStoreName() {
    return  localStorage.getItem('storeName') ?? 'undefined';
  }

  storeCountry() {
    return  localStorage.getItem('storeCountry') ?? 'undefined';
  }

  getFormComplete() {
    return this.formCompleted.value;
  }

  setNavResponse(obj) {
    this.navFeedbackResponse.push(obj);
  }

  getNavResponse() {
    return this.navFeedbackResponse;
  }

  /** Insert Feedback */
  create(data: any): Observable<any> {
    return this.http.post<any>(`${environment.rest.createUrl}`, data, httpOptions).pipe(
      tap(_ => console.log('inserting feedback')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  /** Update Feedback */
  update(data: any): Observable<any> {
    return this.http.post<any>(`${environment.rest.updateUrl}`, data, httpOptions).pipe(
      tap(_ => console.log('updating feedback')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /** Insert Feedback Tmp */
  create_tmp(data: any): Observable<any> {
    return this.http.post<any>(`${environment.rest.createTmpUrl}`, data, httpOptions).pipe(
      tap(_ => console.log('inserting feedback')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /** Update Feedback Tmp */
  update_tmp(data: any): Observable<any> {
    return this.http.post<any>(`${environment.rest.updateTmpUrl}`, data, httpOptions).pipe(
      tap(_ => console.log('Updating feedback')),
      catchError(this.handleError<Fitter[]>('searchFitters', []))
    );
  }

  /** Get Feedback Tmp */
  get_tmp(email?: any): Observable<any> {
    const query = (email) ? '?customer_email=' + '"' + email + '"' : '';
    return this.http.get<Shared[]>(`${environment.rest.getTmpUrl}` + query, httpOptions).pipe(
      tap(_ => this.log(`found Feedback matching "${email}"`)),
      catchError((err => {
        return throwError(err);
      }))
    );
  }

  /** Delete Feedback Tmp */
  delete_tmp(data: any): Observable<any> {
    return this.http.post<any>(`${environment.rest.deleteTmpUrl}`, data, httpOptions).pipe(
      tap(_ => console.log('Deleting feedback')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  send(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(`${environment.rest.send}`, data, httpOptions).pipe(
      tap(_ => console.log('Sending feedback to nav')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getStoreLocation(IP) {
    return this.http.get<any>(`${environment.apiStoreUrl}?ip=${IP}`);
  }

  getReasonCodes(type?: string) {
    // tslint:disable-next-line:prefer-const
    const filter = type !== undefined ? `?type=${type}` : '';
    return this.http.get<any>(`${environment.apiGetReasonCode}${filter}`, httpOptions);
  }

  getQrCode() {
    const lang = this.getLocaleId();
    const storeName = encodeURIComponent('WED2B ' + this.getStoreName());
    console.log(storeName);
    return this.http.get<any>(`${environment.qr_code_generator.url}create?access-token=${environment.qr_code_generator.access_token}&qr_code_text=https://www.reviews.co.uk/instore/index/430373/${encodeURIComponent(storeName)}?lang=${lang}${environment.qr_code_generator.format.image_format}${environment.qr_code_generator.format.image_width}${environment.qr_code_generator.format.frame_name}${environment.qr_code_generator.format.frame_text}${environment.qr_code_generator.format.frame_icon_name}${environment.qr_code_generator.format.qr_code_logo}${environment.qr_code_generator.format.frame_color}${environment.qr_code_generator.format.foreground_color}${environment.qr_code_generator.format.marker_left_template}${environment.qr_code_generator.format.marker_right_template}${environment.qr_code_generator.format.marker_bottom_template}`, {responseType: 'text' as 'json'});
  }

  submitRequestForm(data: any): Observable<any>  {
    console.log(data);
    return this.http.post<any>(`${environment.rest.submitRequestForm}`, data, httpOptions).pipe(
      tap(_ => console.log('Sending feedback to nav')),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`SharedService: ${message}`);
  }
}
