<div class="search-component">
  <div class="outer-search-box">
    <div class="inner-search-box">
      <div class="inner-product-search-box">
      <label for="product-search-box" i18n></label>
      <input id="product-search-box" [disabled]="formCompleted" (focus)="hideList = false; this.activeScanner = false;" (blur)="productSearchBlur()" name="product" i18n-placeholder="@@feedback.product.id.placeholder" placeholder="Enter Product Name" autocomplete="off" #searchProductBox [(ngModel)]="prod.id" class="search-box" (input)="searchProducts(prod.id)" />
      <div *ngIf="isLocationLoading" class="spinner-outer-wrapper">
        <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
        <div class="spinner-logo-wrapper">
          <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
        </div>
      </div>
      </div>
      <div [hidden]="!barcodeValue">
        {{ barcodeValue }}
      </div>
      <input type="hidden" [(ngModel)]="prod.barcodeValue" name="barcodeValue">
      <button id="bar-code-btn" (click)="startScanner()" [disabled]="formCompleted || this.barcodeButtonDisabled || isLocationLoading"><i class="fas fa-barcode"></i></button>
      <div [hidden]="!prod.barcodeValue">
        {{ prod.barcodeValue }}
      </div>
      <ul class="search-result" *ngIf="!hideList">
        <li *ngFor="let prod of product$ | async" (click)="getProductItem(prod)" [ngClass]="prod.Unit_Price === '0'? 'disabled': ''">
          <a>
            {{ prod.Name }} - <span class="product-search-result-size">{{ prod.ColourSize }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="scanner-container" *ngIf="activeScanner" style="max-width: 400px; max-height: 200px;">
    <scandit-sdk-barcode-picker
      [enableCameraSwitcher]="false"
      [accessCamera]="true"
      [cameraType] ="cameraType"
      [cameraSettings]="cameraSettings"
      [scanSettings]="settings"
      [camera]="selectedCamera"
      [videoFit]="'cover'"
      [enableTapToFocus]="true"
      [enableTorchToggle]="false"
      [playSoundOnScan]="true"
      [vibrateOnScan]="true"
      (scan)="onScan($event)"
      (error)="errorScan($event)">
    </scandit-sdk-barcode-picker>
  </div>
</div>
