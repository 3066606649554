import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {Product} from './product';
import { MessageService } from './message.service';
import {environment} from '../environments/environment';
import {SharedService} from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private productUrl = environment.apiProductUrl;
  private productAddonUrl = environment.apiAddonProductUrl;
  private productNameSource = new BehaviorSubject(''); // Default Value
  currentProduct = this.productNameSource.asObservable();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private sharedService: SharedService) {}

  choosenProducts: Array<object> = [];
  getProduct() {
    return of(this.choosenProducts);
  }
  getProductLength() {
    return this.choosenProducts.length;
  }
  getAddOnProduct() {
    return this.http.get<Product[]>(`${this.productAddonUrl}`).pipe(
      // @ts-ignore
      tap(_ => this.log(`addon products retrieved`)),
      catchError(this.handleError<Product[]>('searchProduct', []))
    );
  }
  addProduct(item) {
    // tslint:disable-next-line:no-shadowed-variable
    // @ts-ignore
    // tslint:disable-next-line:no-shadowed-variable
    const obj = this.choosenProducts.find(obj => obj.Item_No + '_' + obj.Variant_Code === item.Item_No + '_' + item.Variant_Code);
    if (obj === undefined) {
      const feedbackObj = this.sharedService.getFeedbackObj();
      if (feedbackObj[0].basket) {
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        const isInBasket = feedbackObj[0].basket.find(bsk => bsk.Item_No + '_' + bsk.Variant_Code === item.Item_No + '_' + item.Variant_Code);
        if (isInBasket) {
          item.in_basket = true;
        }
      }
      this.choosenProducts.push(item);
    }
  }
  removeProduct(i) {
    this.choosenProducts.splice(i , 1);
    this.sharedService.removeFeedbackProductObj(i);
  }
  /* GET heroes whose name contains search term */
  searchProduct(term: object): Observable<Product[]> {
    console.log(term);
    // @ts-ignore
    let param = '';
    // @ts-ignore
    if (term.barcodeValue !== null && term.barcodeValue !== '') {
      // @ts-ignore
      param = `?barcode=${term.barcodeValue}`;
    }else {
      // @ts-ignore
      param = `?id=${term.id}`;
    }
    // @ts-ignore
    if (typeof term.attribute_set !== 'undefined' && term.attribute_set !== null && term.attribute_set !== '') {
      // @ts-ignore
      param += `&attribute_set=${term.attribute_set}`;
      // @ts-ignore
    } else if (typeof term.product_group_code !== 'undefined' && term.product_group_code !== null && term.product_group_code !== '') {
      // @ts-ignore
      param += `&product_group_code=${term.product_group_code}`;
    }
    // @ts-ignore
    param += `&locale=${term.locale}`;
    if (!term) {
      // if not search term, return empty fitter array.
      return of([]);
    }
    // @ts-ignore
    return this.http.get<Product[]>(`${this.productUrl}` + param).pipe(
      // @ts-ignore
      tap(_ => this.log(`found fitters matching "${term.id}"`)),
      catchError(this.handleError<Product[]>('searchProduct', []))
    );
  }

  private setHttpHeader() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Request-Headers', '*')
      .set('Access-Control-Allow-Origin', '*'); // Over here! (It should be Access-Control-Allow-Origin)
    const options = { headers };
    return options;
  }

  getProductName(productName: string) {
    this.productNameSource.next(productName);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`FitterService: ${message}`);
  }
}
