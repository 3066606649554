import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './in-memory-data.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { FitterSearchComponent } from './fitter-search/fitter-search.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';
import { ProductComponent } from './product/product.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { library } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompleteComponent } from './complete/complete.component';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { SpinnerDirective } from './spinner.directive';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { ScanditSdkModule } from 'scandit-sdk-angular';
const licenseKey = environment.scandit.licenseKey;
const engineLocation = environment.scandit.engineLocation;
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSidenavModule, MatTableModule} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SearchFilterPipe } from './search-filter.pipe';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import { FeedbackListComponent } from './feedback-list/feedback-list.component';
import {MatSortModule} from '@angular/material/sort';
import { MaskPipe } from './mask.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import { RequestFormComponent } from './request-form/request-form.component';
import {MatButtonModule} from "@angular/material/button";

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CustomerComponent,
    FitterSearchComponent,
    CustomerSearchComponent,
    ProductComponent,
    ProductSearchComponent,
    CompleteComponent,
    SpinnerDirective,
    SearchFilterPipe,
    FeedbackListComponent,
    MaskPipe,
    RequestFormComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatDialogModule,
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // environment.production ?
    // [] : HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // ),
    BrowserAnimationsModule,
    BarcodeScannerLivestreamModule,
    NgbModule,
    ScanditSdkModule.forRoot(licenseKey, {engineLocation, preloadEngine: true, preloadBlurryRecognition: true}),
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDividerModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatButtonModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'GPB'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule, {
//   providers: [{provide: LOCALE_ID, useValue: 'en-GB' }]
// }).catch(err => console.error(err));
