<div class="feedback-loading-shade"
     *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="example-rate-limit-reached"></div>
</div>
<div class="mat-elevation-z8">
<!--&lt;!&ndash;  <table mat-table [dataSource]="dataSource" class="demo-table"&ndash;&gt;-->
<!--       matSort matSortActive="created_at" matSortDisableClear matSortDirection="asc">-->
<!--  &lt;!&ndash; Position Column &ndash;&gt;-->
<!--  <ng-container matColumnDef="created_at">-->
<!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>-->
<!--    <td mat-cell *matCellDef="let element"> {{element.created_at | date:'medium'}} </td>-->
<!--  </ng-container>-->

<!--  &lt;!&ndash; Name Column &ndash;&gt;-->
<!--  <ng-container matColumnDef="fitter">-->
<!--    <th mat-header-cell *matHeaderCellDef>Consultant</th>-->
<!--    <td mat-cell *matCellDef="let element"> {{element.fitter[0].First_Name+' '+element.fitter[0].Last_Name}} </td>-->
<!--  </ng-container>-->

<!--  &lt;!&ndash; Weight Column &ndash;&gt;-->
<!--  <ng-container matColumnDef="customer">-->
<!--    <th mat-header-cell *matHeaderCellDef>Customer</th>-->
<!--    <td mat-cell *matCellDef="let element"> {{element.customer[0].First_Name+' '+element.customer[0].Last_Name}} </td>-->
<!--  </ng-container>-->

<!--  &lt;!&ndash; Symbol Column &ndash;&gt;-->
<!--  <ng-container matColumnDef="send">-->
<!--    <th mat-header-cell *matHeaderCellDef>Action</th>-->
<!--    <td mat-cell *matCellDef="let element" (click)="sendFeedback(element, $event)" [ngClass]="!element.complete ? 'fa fa-upload' : 'fa fa-check'"></td>-->
<!--  </ng-container>-->
<!--  &lt;!&ndash; Row shown when there is no matching data. &ndash;&gt;-->
<!--  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
<!--</table>-->
  <table mat-table
         [dataSource]="dataSource" multiTemplateDataRows
         class="list-table" matSort matSortActive="created_at" matSortDisableClear matSortDirection="asc">
    <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
      <ng-container *ngIf="column==='created_at'"><th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th></ng-container>
      <ng-container *ngIf="column!=='created_at'"><th mat-header-cell *matHeaderCellDef> {{column}} </th></ng-container>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column === 'created_at'">{{element[column]}}</span>
        <span *ngIf="column === 'fitter' || column === 'customer'">{{element[column][0].First_Name+' '+element[column][0].Last_Name}}</span>
        <i *ngIf="column === 'send'" (click)="sendFeedback(element, $event)" [ngClass]="!element.complete ? 'fa fa-upload' : 'fa fa-check'"></i>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="product-element-detail"
             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <table class="product-element-diagram">
            <div style="text-align: center; border-top: 1px solid rgba(0, 0, 0, 0.12); background: #e1dad1; padding: 6px 0;"><!-- [hidden]="product.starRating > 0 && product.hide === undefined" *ngIf="product.Attribute_Set === 'WED2B'" -->
              <b class="colour-size">{{ element.customer_email }}</b>
            </div>
            <div *ngFor="let product of element['product']" class="product-element">
              <div class="product-element-inner"><!-- [hidden]="product.starRating > 0 && product.hide === undefined" *ngIf="product.Attribute_Set === 'WED2B'" -->
                <tr>
                  <td class="inner-span product-name">{{ product.Name.trim() | uppercase }}</td>
                  <td><ngb-rating (click)="getStarRating(product)" [(rate)]="product.starRating" [max]="5" [readonly]="product.hasStarRating"></ngb-rating></td>
                </tr>
                <tr>
                  <td class="colour-size">{{ product.ColourSize | uppercase }}</td>
                  <td class="product-list-price">{{ product.Unit_Price | currency: product.Currency_Code: 'symbol': '1.2-2' }}&nbsp;<span style="color: red;font-size: 10px;font-weight: bolder" *ngIf="product.starRating === 0">Please rate your product</span></td>
                </tr>
              </div>
            </div>
          </table>
          <table class="product-element-diagram">
            <div style="margin:12px; box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)">
              <h1>Basket</h1>
              <div *ngFor="let product of element['basket']" class="product-element" style="background-color: white">
                <div class="product-element-inner">
                  <tr>
                    <td class="inner-span product-name">{{ product.Name.trim() | uppercase }}</td>
                    <td>Qty:&nbsp;<span>{{product.qty}}</span></td>
                  </tr>
                  <tr>
                    <td class="colour-size">{{ product.ColourSize | uppercase }}</td>
                    <td class="product-list-price">{{ product.Unit_Price | currency: product.Currency_Code: 'symbol': '1.2-2' }}</td>
                  </tr>
                </div>
              </div>
              <table *ngIf="!element['basket'].length" _ngcontent-nrw-c102="" class="ng-tns-c102-0"><tr _ngcontent-nrw-c102="" class="mat-row ng-tns-c102-0 ng-star-inserted"><td _ngcontent-nrw-c102="" class="mat-cell ng-tns-c102-0">No data were found!</td></tr><!----></table>
            </div>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="product-element-row"
        [class.product-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="product-detail-row"></tr>
  </table>
  <table>
  <tr class="mat-row" *ngIf="!hasData">
    <td class="mat-cell">No data were found!</td>
  </tr>
  </table>
</div>
