import {HostListener, Inject, NgModule} from '@angular/core';
import {Routes, RouterModule, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate, Router, NavigationStart} from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { ProductComponent } from './product/product.component';
import {CompleteComponent} from './complete/complete.component';
import {APP_BASE_HREF} from '@angular/common';
import {FitterService} from './fitter.service';
import {FeedbackListComponent} from './feedback-list/feedback-list.component';
import {RequestFormComponent} from './request-form/request-form.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'product', component: ProductComponent },
  { path: 'complete', component: CompleteComponent },
  { path: 'feedback-list', component: FeedbackListComponent },
  { path: 'request-form', component: RequestFormComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private fitterService: FitterService, private router: Router, @Inject(APP_BASE_HREF) public baseHref: string) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url === '/complete') {
          // window.location.replace('/');
        }
        // @ts-ignore
        if (event.url !== '/dashboard' && event.url !== '/' && event.url !== '/request-form') {
          this.checkCustomerExist(event);
        }
      }
    });
  }

  checkCustomerExist(event) {
    if (this.fitterService.choosenFitter.length === 0 && event.url !== '/feedback-list') {
      console.log(this.baseHref);
      window.location.replace(this.baseHref);
    }
  }
}
