import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.less']
})
export class RequestFormComponent implements OnInit {
  title = $localize`:@@feedback.request.form:Request Form`;
  categories: any = [
    {id: 1, name: 'Bug Report', icon: 'bug_report'},
    {id: 2, name: 'New Feature', icon: 'settings_suggest'},
    {id: 3, name: 'Improve Functionality', icon: 'construction'}
  ];
  public requestForm: FormGroup;
  public loading = false;
  public submitRequestFormResponse = {};
  constructor(private sharedService: SharedService, private fb: FormBuilder) {
    this.sharedService.setTitle(this.title);
    this.requestForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(25)]],
      category: [null, [Validators.required]],
      attachments: this.fb.array([]),
      fileName: [''],
      requestedBy: this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
      })
    });
  }

  ngOnInit(): void {
  }

  // We will create multiple form controls inside defined form controls photos.
  createItem(data): FormGroup {
    // console.log(data);
    return this.fb.group(data);
  }

  // Help to get all photos controls as form array.
  get attachments(): FormArray {
    return this.requestForm.get('attachments') as FormArray;
  }

  detectFiles(event) {
    const files = event.target.files;
    // console.log(files);
    if (files) {
      for (const file of files) {
        // console.log(file);
        this.requestForm.get('fileName').patchValue(file.name);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          // console.log('e.target.result', e.target.result);
          this.attachments.push(this.createItem({
            file: {name: file.name, type: file.type},
            url: e.target.result  // Base64 string for preview image
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.requestForm.get('fileName').patchValue('');
    }
  }

  removeAttachment(i){
    this.attachments.removeAt(i);
    if (this.attachments.value.length === 0) {
      this.requestForm.get('fileName').patchValue('');
    }
  }

  onSubmit(requestFormDirective) {
    // TODO: Use EventEmitter with form value
    this.loading = true;
    const payload = this.requestForm.value;
    this.sharedService.submitRequestForm(payload).subscribe(response => {
        this.submitRequestFormResponse = response;
        console.log(this.submitRequestFormResponse);
    }, error => {
      this.loading = false;
      alert(JSON.stringify(error));
    }, () => {
      console.log('complete');
      this.loading = false;
      // @ts-ignore
      if (this.submitRequestFormResponse.error) {
        requestFormDirective.reset();
        // @ts-ignore
        this.attachments.clear();
      }
      // @ts-ignore
      alert(this.submitRequestFormResponse.message);
    });
  }

}
