<div class="search-component">
<!--  <p><label for="customer-search-box" i18n>Customer Search</label></p>-->
  <div class="outer-fitter-search-box">
    <button class="btn btn-magnifying-glass" (click)="focusInput();" [disabled]="isLocationLoadingCustomerList || isLocationLoading" [ngClass]="isLocationLoadingCustomerList?' disabled-search-btn':''">
      <!--      <i class="fa fa-search" *ngIf="!showSearch"></i>-->
      <span *ngIf="!showSearch" class="material-symbols-outlined">search</span>
      <!--      <i class="fa fa-arrow-alt-circle-left" *ngIf="showSearch"></i>-->
      <span *ngIf="showSearch" class="material-symbols-outlined">arrow_back_ios</span>
    </button>
    <a style="position: relative" class="enter-btn" [ngClass]="isLocationLoadingCustomerList || formCompleted?' disabled':''" (click)="getCustomerList()" *ngIf="!showSearch">
      <span i18n="@@feedback.customer.button.updateList">Update List</span>
      <div *ngIf="isLocationLoadingCustomerList" class="spinner-outer-wrapper">
        <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
        <div class="spinner-logo-wrapper">
          <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
        </div>
      </div>
    </a>
    <div class="inner-fitter-search-box" *ngIf="showSearch">
      <input id="customer-search-box" autofocus [disabled]="formCompleted" [value]="customerName" (focus)="hideList = false" i18n-placeholder="@@feedback.customer.email.placeholder" placeholder="Enter Customer Email" autocomplete="off" #searchCustomerBox class="search-box" (input)="searchCustomers(searchCustomerBox.value)" />
      <div *ngIf="isLocationLoading" class="spinner-outer-wrapper"><!-- *ngIf="isLocationLoading" -->
        <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
        <div class="spinner-logo-wrapper">
          <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
        </div>
      </div>
    </div>
    <a routerLink="/product" [className]="customerName ? 'enter-btn' : 'enter-btn disabled'" i18n="@@feedback.customer.button.next">Next</a>
    <div class="customer-email" *ngIf="selectedEmail">
      <span class="email" [ngClass]="{ 'visible': visible }" >{{selectedEmail}}</span>
      <span class="eye-wrapper" (click)="onClick()">
          <i *ngIf="visible" class="material-symbols-outlined eye">visibility</i>
          <i *ngIf="!visible" class="material-symbols-outlined eye">visibility_off</i>
        </span>
    </div>
    <div *ngIf="customers$ | async as cus">
      <div *ngIf="cus.length > 0">
        <div class="customer-search-header">
          <div class="list-customer-name" i18n="@@feedback.customer.header.name">Name</div>
          <div class="list-customer-e-mail" i18n="@@feedback.customer.header.email">E-mail</div>
          <div class="list-customer-has-feedback">
            <mat-icon (click)="toggleInfo()" aria-hidden="false" aria-label="menu icon">help</mat-icon>
            <div class="tooltip-info" *ngIf="shoInfo">
                <span class="check-icon">
                  <mat-icon aria-hidden="false" aria-label="check icon">check</mat-icon>&nbsp;<small i18n="@@feedback.customer.tooltip.check">Completed</small>
                </span>
                <span class="priority_high-icon">
                  <mat-icon aria-hidden="false" aria-label="priority_high icon">priority_high</mat-icon>&nbsp;<small i18n="@@feedback.customer.tooltip.priority">Incomplete</small>
                </span>
            </div>
          </div>
        </div>
        <ul class="customer-search-result search-result">
          <li *ngFor="let customer of cus" (click)="getCustomer(customer)">
            <div class="list-customer-name" *ngIf="customer.E_Mail">
              {{ customer.Name }}
            </div>
            <div class="list-customer-e-mail">
              {{ customer.E_Mail | mask}}
            </div>
            <div class="list-customer-has-feedback">
              <mat-icon class="priority_high-icon" *ngIf="!customer.Feedback_Exists" aria-hidden="false" aria-label="priority_high icon">priority_high</mat-icon>
              <mat-icon class="check-icon" *ngIf="customer.Feedback_Exists" aria-hidden="false" aria-label="check icon">check</mat-icon>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!--<pre>{{customers$ | async | json}}</pre>-->
