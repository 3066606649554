<div class="outer-summary-container">
  <h4 class="completed-header">
    <div class="feedbacl-message" role="alert" i18n="@@feedback.complete.header.completed">Thank You Feedback Completed</div>
  </h4>
</div>
<div class="start-again-container"><button i18n="@@feedback.complete.startAgain" (click)="finish()">Start Again</button></div>
<div style="width: 250px;margin: 100px auto;display: flex;" class="qrcode-wrapper" [innerHTML]="qrcode"></div>
<div *ngIf="error">
  <h3>Error:</h3>
  <ul>
    <li *ngFor="let err of error">
      {{err.field}} - {{err.message}}
    </li>
  </ul>
</div>
