<div class="search-component">
  <div class="outer-search-box">
    <div class="inner-search-box">
      <label for="fitter-search-box"></label>
      <input id="fitter-search-box" type="text" [disabled]="formCompleted" [value]="fitterName" (focus)="hideList = false" name="fitter" i18n-placeholder="@@feedback.fitter.id.placeholder" placeholder="Enter Name or Surname" autocomplete="off" #searchBox class="search-box" (input)="search(searchBox.value)" />
        <div *ngIf="isLocationLoading" class="spinner-outer-wrapper"> <!-- *ngIf="isLocationLoading" -->
          <mat-spinner diameter="45" strokeWidth="3"></mat-spinner>
          <div class="spinner-logo-wrapper">
            <img src="../../assets/img/wed2b-primary-icon.svg" width="24" alt="infinity logo">
          </div>
        </div>
    </div>
        <a routerLink="/customer" [className]="fitterName ? 'enter-btn' : 'enter-btn disabled'" class="enter-btn" i18n="@@feedback.fitter.button.next">Next</a>
    <div>
      <ul class="search-result" *ngIf="!hideList">
        <li *ngFor="let fitter of fitters$ | async" (click)="getFitter(fitter)">
          <span *ngIf="fitter.First_Name">{{ fitter.First_Name }} {{ fitter.Last_Name }} {{ fitter.ID }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
