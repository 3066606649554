import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  title = $localize`:@@feedback.dashboard:Dashboard`;
  constructor( private sharedService: SharedService) {
    this.sharedService.setTitle(this.title);
  }

  ngOnInit() {
  }

}
