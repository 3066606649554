import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const arr = value.split('@');
    return arr[0].substring(0, 3) + '*'.repeat(10) + '@' + arr[1];
  }

}
