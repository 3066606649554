import { Component, OnInit } from '@angular/core';
import {ProductService} from '../product.service';
import {Observable, of} from 'rxjs';
import {Customer} from '../customer';
import {CustomerService} from '../customer.service';
import { SharedService } from '../shared.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.less']
})
export class CustomerComponent implements OnInit {
  choosenCustomer: Array<object>;
  title = $localize`:@@feedback.customer:Customer`;
  customerFullName = 'Juliano Vargas';
  previousStarRateObj = [
    {
      Item_No: 'I00087',
      Variant_Code: 'IV-06',
      Magento_Sku: 'I00087_IV-06',
      ColourSize: 'IVORY/BLUSH, UK SIZE 6',
      Product_Name: 'Diana',
      Image_URL: 'http://w2b-ims/webshop/media/anna_sorrano/diana/diana-sheath-wedding-dress-front-anna-sorrano.jpg',
      Description: 'It doesn\'t get more elegant than this understated sheath wedding gown. With delicate illusion lace straps and a stunning v-neckline. The detailed bodice has exquisite embroidered lace leading to the figure defining skirt.',
      Unit_Price: '599',
      Currency_Code: 'GBP',
      starRating: 4,
      No: 'HO-CUST263691',
      Name: 'Juliano Vargas',
      E_Mail: 'julianovargas2000@hotmail.com',
      purchased: 'yes',
      reason: 'OTHER',
      comments: 'This is a comments',
      locale: 'en-GB',
      store_code: 'undefined',
      in_basket: 'yes',
      date_created: '1/11/2023'
    },
    {
      Item_No: 'I00087',
      Variant_Code: 'IV-06',
      Magento_Sku: 'I00087_IV-06',
      ColourSize: 'IVORY/BLUSH, UK SIZE 6',
      Product_Name: 'Diana',
      Image_URL: 'http://w2b-ims/webshop/media/anna_sorrano/diana/diana-sheath-wedding-dress-front-anna-sorrano.jpg',
      Description: 'It doesn\'t get more elegant than this understated sheath wedding gown. With delicate illusion lace straps and a stunning v-neckline. The detailed bodice has exquisite embroidered lace leading to the figure defining skirt.',
      Unit_Price: '599',
      Currency_Code: 'GBP',
      starRating: 2,
      No: 'HO-CUST263691',
      Name: 'Juliano Vargas',
      E_Mail: 'julianovargas2000@hotmail.com',
      purchased: 'no',
      reason: 'CFFA2',
      comments: '',
      locale: 'en-GB',
      store_code: 'undefined',
      date_created: '1/11/2023'
    },
    {
      Item_No: 'I00087',
      Variant_Code: 'IV-06',
      Magento_Sku: 'I00087_IV-06',
      ColourSize: 'IVORY/BLUSH, UK SIZE 6',
      Product_Name: 'Diana',
      Image_URL: 'http://w2b-ims/webshop/media/anna_sorrano/diana/diana-sheath-wedding-dress-front-anna-sorrano.jpg',
      Description: 'It doesn\'t get more elegant than this understated sheath wedding gown. With delicate illusion lace straps and a stunning v-neckline. The detailed bodice has exquisite embroidered lace leading to the figure defining skirt.',
      Unit_Price: '599',
      Currency_Code: 'GBP',
      starRating: 5,
      No: 'HO-CUST263691',
      Name: 'Juliano Vargas',
      E_Mail: 'julianovargas2000@hotmail.com',
      purchased: 'yes',
      reason: 'OTHER',
      comments: 'This is a comments',
      locale: 'en-GB',
      store_code: 'undefined',
      date_created: '1/11/2023'
    },
    {
      Item_No: 'I00087',
      Variant_Code: 'IV-06',
      Magento_Sku: 'I00087_IV-06',
      ColourSize: 'IVORY/BLUSH, UK SIZE 6',
      Product_Name: 'Diana',
      Image_URL: 'http://w2b-ims/webshop/media/anna_sorrano/diana/diana-sheath-wedding-dress-front-anna-sorrano.jpg',
      Description: 'It doesn\'t get more elegant than this understated sheath wedding gown. With delicate illusion lace straps and a stunning v-neckline. The detailed bodice has exquisite embroidered lace leading to the figure defining skirt.',
      Unit_Price: '599',
      Currency_Code: 'GBP',
      starRating: 1,
      No: 'HO-CUST263691',
      Name: 'Juliano Vargas',
      E_Mail: 'julianovargas2000@hotmail.com',
      purchased: 'no',
      reason: 'OTHER',
      comments: '',
      locale: 'en-GB',
      store_code: 'undefined',
      in_basket: 'yes',
      date_created: '1/11/2023'
    }
  ];
  public dialogRef: any;
  constructor(private customerService: CustomerService, private sharedService: SharedService, public dialog: MatDialog) {
    this.sharedService.setTitle(this.title);
  }

  ngOnInit() {
    const feedbackObj = this.sharedService.getFeedbackObj()[0];
    if (feedbackObj.product) {
      if (feedbackObj.product.length > 0) {
        if (feedbackObj.id) {
          this.sharedService.update_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
            if (data.last_id !== '0') {
              this.sharedService.setFeedbackMiscObj('id', data.last_id);
            }
            console.log(data);
          }, error => {
            console.log('error');
          }, () => {
            console.log('complete');
          });
        } else {
          this.sharedService.create_tmp(this.sharedService.getFeedbackObj()[0]).subscribe(data => {
            if (data.last_id !== '0') {
              this.sharedService.setFeedbackMiscObj('id', data.last_id);
            }
            console.log(data);
          }, error => {
            console.log('error');
          }, () => {
            console.log('complete');
          });
        }
      }
    }
    this.customerService.getCustomer().subscribe(res => {
      this.choosenCustomer = res;
      console.log(this.choosenCustomer);
      // @ts-ignore
      this.customerFullName = res.First_Name;
    });
  }

  getCustomerStarRate(templateRef) {
    this.dialogRef = this.dialog.open(templateRef, {
      id: 'customerPreviousStarRate',
      width: '500px',
      height: '700px'
    });
  }

  strReplace(value: string): string {
    return value.replace(/http:\/\/w2b-ims/g, 'https://w2bcwa.blob.core.windows.net');
  }

}
