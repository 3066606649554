import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {Customer} from './customer';
import { MessageService } from './message.service';
import { SharedService} from './shared.service';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private customersUrl = environment.apiCustomerUrl;  // URL to web api
  private customerNameSource = new BehaviorSubject(''); // Default Value
  currentCustomer = this.customerNameSource.asObservable();
  private customerEmailSource = new BehaviorSubject(''); // Default Value
  currentEmailCustomer = this.customerEmailSource.asObservable();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private sharedService: SharedService) {}

  choosenCustomer: Array<object> = [];

  getCustomer() {
    return of(this.choosenCustomer);
  }

  /* GET heroes whose name contains search term */
  searchCustomers(term: string): Observable<Customer[]> {
    if (!term.trim()) {
      // if not search term, return empty fitter array.
      return of([]);
    }
    const storeName = this.sharedService.getStoreName();
    return this.http.get<Customer[]>(`${this.customersUrl}?email=${term}&storeName=${storeName}`).pipe(
      tap(_ => this.log(`found fitters matching "${term}"`)),
      catchError(this.handleError<Customer[]>('searchCustomers', []))
    );
  }

  getCustomerName(custName: string) {
    this.customerNameSource.next(custName);
  }

  getCustomerEmail(custEmail: string) {
    this.customerEmailSource.next(custEmail);
  }

  addCustomer(item) {
    this.choosenCustomer.splice(0, 1);
    this.choosenCustomer.push(item);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`FitterService: ${message}`);
  }
}
