import {Component, HostListener, Inject, LOCALE_ID, isDevMode, OnInit} from '@angular/core';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import {FitterService} from './fitter.service';
import {CustomerService} from './customer.service';
import {SharedService} from './shared.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  isMobile = null;
  menuOpen = false;
  showSupport = false;
  storeName = 'undefined';
  storeCountry = 'undefined';
  storeCode = 'undefined';
  storeLocationIp = null;
  isLocationLoading = true;
  counter = 0;
  clicks = 0;
  interval;
  languageList = [ // <--- add this
    { baseHref: '/', code: 'en', locale: 'en-GB', label: 'English', image: 'assets/img/uk.svg' },
    { baseHref: '/nl/', code: 'nl', locale: 'nl', label: 'Dutch', image: 'assets/img/nl.svg' },
    { baseHref: '/de/', code: 'de', locale: 'de', label: 'Deutsch', image: 'assets/img/de.svg' },
    { baseHref: '/fr/', code: 'fr', locale: 'fr', label: 'Français', image: 'assets/img/fr.svg' }
  ];
  obj: any;
  storeLocation: any;
  fitterName: string;
  customerName: string;
  formCompleted: boolean;
  myDate: Date;
  myTime: Date;
  status: boolean;
  // tslint:disable-next-line:variable-name
  locale: any;
  title = $localize`:@@feedback:Feedback`;
  @HostListener('window:beforeunload', ['$event'])
   doSomething(ev) {
    if (!this.sharedService.skip) {
      return false;
    }
  }
  @HostListener('document:click', ['$event'])
  clickOut(event) {
    this.status = false;
  }
  constructor(private fitterService: FitterService,
              private customerService: CustomerService,
              private sharedService: SharedService,
              @Inject(LOCALE_ID) protected localeId: string,
              private deviceService: DeviceDetectorService,
              @Inject(APP_BASE_HREF) public baseHref: string) {
    this.sharedService.skip = false;
    console.log(this.localeId);
    if (this.localeId === 'en-US') {
      this.localeId = 'en-GB';
    }
    if (this.localeId !== this.sharedService.getLocaleIdStorage()) {
      this.sharedService.setLocaleIdStorage();
      this.sharedService.updateQrCodeStorage(true);
    }
    if (isDevMode()) {
      console.log('👋 Development!');
    } else {
      console.log('💪 Production!');
    }
    const storeIp = localStorage.getItem('storeLocationIp');
    this.getStoreLocation(storeIp);
  }
  ngOnInit(): void {
    this.sharedService.setFeedbackObj(
      {
        purchased: 'no',
        reason: '',
        // comments: '',
        locale: this.localeId,
        store_code: this.storeCode
      }
    );

    const userAgent = this.deviceService.getDeviceInfo().userAgent;
    if (userAgent.indexOf('Nokia T20') === -1) {
      this.isMobile = this.deviceService.isMobile();
    } else {
      this.isMobile = false;
    }
    this.locale = this.languageList.find(({locale}) => locale === this.localeId);
    this.ucTime();
    this.fitterService.currentFitter.subscribe(fitterName => this.fitterName = fitterName);
    this.customerService.currentCustomer.subscribe(customerName => this.customerName = customerName);
    this.sharedService.getTitle.subscribe(updateTitle => this.title = updateTitle);
    this.sharedService.formCompleted.subscribe(formCompleted => this.formCompleted = formCompleted);
    this.sharedService.setFormCompleted(false);
    // console.log(this.sharedService.getFeedbackObj());
  }
  ucTime(): void {
    setInterval(() => {
      this.myDate = new Date();
      this.myTime = new Date();
    }, 1000);
  }

  toggleSwitcher(e: Event) {
    this.status = !this.status;
  }

  getStoreLocation(IP) {
    this.sharedService.getStoreLocation(IP).subscribe(data => {
      if (data.length > 0) {
        data = data[0];
        localStorage.setItem('storeLocationIp', data.Store_IP_Address);
        localStorage.setItem('storeCountry', data.Country_Code);
        localStorage.setItem('storeName', data.Name);
        localStorage.setItem('storeCode', data.No);
        localStorage.removeItem('qrcode');
      }
      console.log(this.storeCountry);
      this.storeName = localStorage.getItem('storeName') ?? 'undefined';
      this.storeCountry = localStorage.getItem('storeCountry') ?? 'undefined';
      this.storeCode = localStorage.getItem('storeCode') ?? 'undefined';
    }, (error) => {
      console.error('error caught in component');
      alert('ERROR: ' + JSON.stringify(error.error.text));
    }, () => {
      this.isLocationLoading = false;
    });
  }

  countTime() {
    this.interval = setInterval(() => {
      this.counter++;
      if (this.counter === 4) {
        this.clicks = 0;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resetCount() {
    this.clicks++;
    if (this.clicks === 4 && this.counter === 0) {
      window.location.href = this.baseHref + 'feedback-list';
      this.clicks = 0;
    }
    if (this.clicks > 4) {
      this.clicks = 0;
    }
    clearInterval(this.interval);
    this.countTime();
    this.counter = 0;
  }
  toggleSupport() {
    this.showSupport = ! this.showSupport;
  }
}
