<app-customer-search></app-customer-search>
<div class="customer-container" *ngIf="choosenCustomer.length">
  <div class="service star-rate" (click)="getCustomerStarRate(customerStarRate)">
    <p class="service-icon-wrapper"><mat-icon class="material-symbols-outlined">person_add</mat-icon></p>
    <p class="service-text-wrapper"><span i18n="@@feedback.customer.previous.ratings">Previous Ratings</span></p>
  </div>
  <div class="service wishlist">
    <p class="service-icon-wrapper"><mat-icon class="material-symbols-outlined">favorite</mat-icon></p>
    <p class="service-text-wrapper"><span i18n="@@feedback.customer.wishlist">Wishlist</span></p>
  </div>
</div>
<ng-template #customerStarRate>
  <div class="customer-star-rated-items">
    <mat-icon matSuffix class="close-rate-modal" mat-dialog-close >close</mat-icon>
    <div *ngFor="let rate of previousStarRateObj; let i = index">
      <div class="purchase-wrapper" *ngIf="i === 0">
        <span  *ngIf="rate.purchased === 'yes'" class="material-symbols-outlined verified">verified</span>
      </div>
      <div class="product-info-wrapper">
        <div class="in-basket-wrapper">
          <div *ngIf="rate.in_basket">
            <span class="material-symbols-outlined shopping_bag">shopping_bag</span>
            <span class="material-symbols-outlined add">check</span>
          </div>
        </div>
        <div class="header-wrapper">
          <h4 mat-line>{{rate.Product_Name | titlecase}}</h4>
        </div>
        <div class="header-sku-price">
          <small mat-line>{{rate.ColourSize}}</small>
          <small mat-line class="price">{{ rate.Unit_Price | currency: rate.Currency_Code: 'symbol': '1.2-2' }}</small>
        </div>
        <div class="star-wrapper">
          <small mat-line><ngb-rating [(rate)]="rate.starRating" [max]="5" [readonly]="true"></ngb-rating></small>
          <span class="date_created">{{rate.date_created}}</span>
        </div>
<!--        <div class="img-description-wrapper">-->
<!--          <img class="product-img" width="100" src="{{ this.strReplace(rate.Image_URL) }}"  alt=""/>-->
<!--          <span class="product-description">{{ rate.Description }}</span>-->
<!--        </div>-->
        <figure class="quote reason">
          <blockquote *ngIf="rate.reason === 'OTHER' "><q>{{rate.comments}}</q></blockquote>
          <blockquote *ngIf="rate.reason !== 'OTHER' "><q>{{rate.reason}}</q></blockquote>
        </figure>
      </div>
    </div>
  </div>
</ng-template>
<!--{{this.choosenCustomer | json}}-->
