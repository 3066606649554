import { Component, Inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../shared.service';
import { ProductService } from '../product.service';
import { CustomerService } from '../customer.service';
import { FitterService } from '../fitter.service';
import { DOCUMENT, KeyValue, APP_BASE_HREF } from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.less']
})

export class CompleteComponent implements OnInit {
  objectKeys = Object.keys;
  obj: any;
  choosenProducts: Array<object>;
  customer: Array<object>;
  fitter: Array<object>;
  navFeedbackResponse: Array<object>;
  qrcode: any;
  title = $localize`:@@feedback.overview:Overview`;
  error: [];
  constructor(private sharedService: SharedService,
              private productService: ProductService,
              private customerService: CustomerService,
              private fitterService: FitterService,
              @Inject(APP_BASE_HREF) public baseHref: string,
              @Inject(DOCUMENT) private document: Document,
              private sanitizer: DomSanitizer)
  {
    this.sharedService.setTitle(this.title);
    this.obj = this.sharedService.getFeedbackObj()[0];
    console.log(this.sharedService.getUpdateQrCodeStorage());
    if (this.sharedService.getQrCodeLocal() && this.sharedService.getUpdateQrCodeStorage() === 'false') {
      this.qrcode = this.sanitizer.bypassSecurityTrustHtml(this.sharedService.getQrCodeLocal());
    } else {
      this.sharedService.getQrCode().subscribe(result => {
        this.sharedService.setQrCodeLocal(result);
        this.qrcode = this.sanitizer.bypassSecurityTrustHtml(this.sharedService.getQrCodeLocal());
        this.sharedService.updateQrCodeStorage(false);
      }, (err) => {
        console.log('error caught in component', err);
        const er = JSON.parse(err.error);
        this.error = er.errors;
        // alert('ERROR: ' + JSON.stringify(error.error.text));
      }, () => {
        console.log('complete');
      });
    }
  }

  ngOnInit() {
    this.productService.getProduct().subscribe(res => {this.choosenProducts = res; }, error => {});
    this.customerService.getCustomer().subscribe(res => {this.customer = res; });
    this.fitterService.getFitter().subscribe(res => {this.fitter = res; });
    this.navFeedbackResponse =  this.sharedService.getNavResponse()[0];
    this.document.body.classList.add('complete');
  }

  toggle(product) {
    product.show = !product.show;
  }

  originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>) {
    return 0;
  }

  isNumber(value) {
    return typeof value === 'number';
  }

  finish() {
    window.location.replace(this.baseHref);
  }

  camelCaseToWords(value: string): string {
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  strReplace(value: string): string {
    return value.replace(/http:\/\/w2b-ims/g, 'https://w2bcwa.blob.core.windows.net');
  }
  getQrcode(Qrcode) {
    // this.dialog.open(Qrcode, {
    //   data: {
    //     animal: 'panda',
    //   },
    // });
  }

}
