import { Injectable, Output, EventEmitter } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {Fitter} from './fitter';
import { MessageService } from './message.service';
import {environment} from '../environments/environment';
import {SharedService} from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class FitterService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private sharedService: SharedService) {}
  choosenFitter: Array<object> = [];
  private fittersUrl = environment.apiFitterUrl;  // URL to web api
  private fitterNameSource = new BehaviorSubject(''); // Default Value
  currentFitter = this.fitterNameSource.asObservable();

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    ),
    params: new HttpParams({}),
    withCredentials: true,
  };

  getNavFitters(): Observable<Fitter[]> {
    return this.http.get<Fitter[]>('http://W2B-UAT-NAV.wdfo.local:7048/DynamicsNAV110/OData/Company(\'WED2B\')/Employees?$filter=startswith(ID, \'ST020\')').pipe(
      tap(_ => this.log('fetched fitters')),
      catchError(this.handleError<Fitter[]>('getNavFitters', []))
    );
  }

  /** GET fitter from the server */
  getFitters(): Observable<Fitter[]> {
    return this.http.get<Fitter[]>(this.fittersUrl).pipe(
      tap(_ => this.log('fetched fitters')),
      catchError(this.handleError<Fitter[]>('getFitters', []))
    );
  }

  /* GET fitters whose name contains search term */
  searchFitters(term: string): Observable<Fitter[]> {
    if (!term.trim()) {
      // if not search term, return empty fitter array.
      return of([]);
    }
    const storeCountry = this.sharedService.storeCountry();
    return this.http.get<Fitter[]>(`${this.fittersUrl}?id=${term}&storeCountry=${storeCountry}`).pipe(
      tap(_ => this.log(`found fitters matching "${term}"`)),
      catchError(this.handleError<Fitter[]>('searchFitters', []))
    );
  }

  getFitterName(fitterName: string) {
    this.fitterNameSource.next(fitterName);
  }
  getFitter() {
    return of(this.choosenFitter);
  }

  addFitter(item) {
    this.sharedService.setFeedbackFitterObj(item);
    this.choosenFitter.splice(0, 1);
    this.choosenFitter.push(item);
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`FitterService: ${message}`);
  }
}
